// src/data/templates.ts

//
// • Hair color template images
//
import redHairImage from './assets/hair_color/red.png';
import blondHairImage from './assets/hair_color/blond.png';
import blackHairImage from './assets/hair_color/black.png';
import brownHairImage from './assets/hair_color/brown.png';
import platinumBlondHairImage from './assets/hair_color/platinum_blond.png';

//
// • Hair style template images
//
import buzzcutImage from './assets/hairstyle/buzzcut.png';
import curlyImage from './assets/hairstyle/tina_curly.png';
import longFlowingImage from './assets/hairstyle/long_flowing.png';
import pompadourImage from './assets/hairstyle/pomp.png';
import undercutImage from './assets/hairstyle/undercut.png';

//
// • LinkedIn template images
//
import professional from './assets/linkedin/formal_headshot.png';
import outdoorProfessional from './assets/linkedin/outdoor_professional.png';
import businessCasual from './assets/linkedin/business_casual.png';
import creativeProfessional from './assets/linkedin/creative_professional.png';
import executive from './assets/linkedin/executive_portrait.png';

//
// • Casual template images
//
import polaroid from './assets/instagram/polaroid.png';
import nightOut from './assets/instagram/night_out.png';
import sporty from './assets/instagram/sporty.png';
import artistic from './assets/instagram/artistic.png';
import casualSmile from './assets/casual_smile.png';

/**
 * Represents a single template within a category.
 */
export interface Template {
  id: string;
  title: string;
  beforeImage: string;
  afterImage: string;
  isPaid: boolean;
  isDiscounted?: boolean;
}

/**
 * Represents a category of templates.
 */
export interface TemplateCategory {
  name: string;
  highlight: string;
  bundlePrice: number;
  templates: Template[];
}

/**
 * An array of template categories, each containing multiple templates.
 */
export const templateCategories: TemplateCategory[] = [
  {
    name: 'LinkedIn Professional',
    highlight: 'LinkedIn photo',
    bundlePrice: 19.99,
    templates: [
      { id: 'linkedin-1', title: 'Formal Headshot', beforeImage: professional, afterImage: professional, isPaid: true, isDiscounted: false },
      { id: 'linkedin-4', title: 'Business Casual', beforeImage: businessCasual, afterImage: businessCasual, isPaid: true },
      { id: 'linkedin-5', title: 'Executive Portrait', beforeImage: executive, afterImage: executive, isPaid: true },
      { id: 'linkedin-3', title: 'Creative Professional', beforeImage: creativeProfessional, afterImage: creativeProfessional, isPaid: true, isDiscounted: false },
      { id: 'linkedin-2', title: 'Outdoor Professional', beforeImage: outdoorProfessional, afterImage: outdoorProfessional, isPaid: true },
    ],
  },
  {
    name: 'Hair Colors',
    highlight: 'hair colors',
    bundlePrice: 11.99,
    templates: [
      { id: 'haircolors-5', title: 'Platinum Blonde hair', beforeImage: platinumBlondHairImage, afterImage: platinumBlondHairImage, isPaid: true },
      { id: 'haircolors-3', title: 'Black hair', beforeImage: blackHairImage, afterImage: blackHairImage, isPaid: true },
      { id: 'haircolors-1', title: 'Blonde hair', beforeImage: blondHairImage, afterImage: blondHairImage, isPaid: true },
      { id: 'haircolors-2', title: 'Red hair', beforeImage: redHairImage, afterImage: redHairImage, isPaid: true },
      { id: 'haircolors-4', title: 'Brown hair', beforeImage: brownHairImage, afterImage: brownHairImage, isPaid: true },
    ],
  },
  {
    name: 'Instagram',
    highlight: 'Casual photo',
    bundlePrice: 11.99,
    templates: [
      { id: 'hinge-1', title: 'Polaroid', beforeImage: polaroid, afterImage: polaroid, isPaid: true },
      { id: 'hinge-4', title: 'Artistic', beforeImage: artistic, afterImage: artistic, isPaid: true },
      { id: 'hinge-3', title: 'Sporty', beforeImage: sporty, afterImage: sporty, isPaid: true },
      { id: 'hinge-5', title: 'Night Out', beforeImage: nightOut, afterImage: nightOut, isPaid: true },
      { id: 'hinge-2', title: 'Smile', beforeImage: casualSmile, afterImage: casualSmile, isPaid: true },
    ],
  },
  {
    name: 'Hairstyles',
    highlight: 'hair styles',
    bundlePrice: 11.99,
    templates: [
      { id: 'hairstyles-1', title: 'Buzzcut', beforeImage: buzzcutImage, afterImage: buzzcutImage, isPaid: true },
      { id: 'hairstyles-3', title: 'Long Flowing', beforeImage: longFlowingImage, afterImage: longFlowingImage, isPaid: true },
      { id: 'hairstyles-4', title: 'Undercut', beforeImage: undercutImage, afterImage: undercutImage, isPaid: true },
      { id: 'hairstyles-5', title: 'Pompadour', beforeImage: pompadourImage, afterImage: pompadourImage, isPaid: true },
      { id: 'hairstyles-2', title: 'Curly', beforeImage: curlyImage, afterImage: curlyImage, isPaid: true },
    ],
  },
  // Add more categories as needed
];

/**
 * Service class to handle template-related operations.
 */
export class TemplateService {
  private categories: TemplateCategory[];

  constructor(categories: TemplateCategory[]) {
    this.categories = categories;
  }

  /**
   * Determines if any of the provided template IDs are paid.
   * @param templateIds - Array of template IDs to check.
   * @returns True if any template is paid, otherwise false.
   */
  public isOrderPaid(templateIds: string[]): boolean {
    for (const id of templateIds) {
      const template = this.findTemplateById(id);
      if (template && template.isPaid) {
        return true;
      }
    }
    return false;
  }

  /**
   * Finds a template by its ID.
   * @param id - The ID of the template to find.
   * @returns The Template object if found, otherwise undefined.
   */
  public findTemplateById(id: string): Template | undefined {
    for (const category of this.categories) {
      const template = category.templates.find((t) => t.id === id);
      if (template) {
        return template;
      }
    }
    return undefined;
  }

  /**
   * (Optional) Retrieves all paid templates.
   * @returns An array of paid Template objects.
   */
  public getPaidTemplates(): Template[] {
    const paidTemplates: Template[] = [];
    for (const category of this.categories) {
      paidTemplates.push(...category.templates.filter((t) => t.isPaid));
    }
    return paidTemplates;
  }

  /**
   * (Optional) Retrieves all free templates.
   * @returns An array of free Template objects.
   */
  public getFreeTemplates(): Template[] {
    const freeTemplates: Template[] = [];
    for (const category of this.categories) {
      freeTemplates.push(...category.templates.filter((t) => !t.isPaid));
    }
    return freeTemplates;
  }

  /**
   * (Optional) Retrieves all templates.
   * @returns An array of all Template objects.
   */
  public getAllTemplates(): Template[] {
    return this.categories.flatMap((category) => category.templates);
  }
}

// Instantiate and export the TemplateService
export const templateService = new TemplateService(templateCategories);
