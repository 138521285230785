// src/components/TemplateSelectionPage.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import { FaChevronLeft, FaChevronRight, FaGift, FaTag, FaBullhorn, FaImage, FaExpand, FaClock } from 'react-icons/fa';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getDoc, doc } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { Link } from 'react-router-dom';
import TemplateSidebar from './TemplateSidebar';

import Navbar from './Navbar';
import { templateCategories, TemplateService, Template } from './templates';
import { auth, analytics, db } from './firebaseConfig';

import 'react-toastify/dist/ReactToastify.css';

// Instantiate the TemplateService
const templateService = new TemplateService(templateCategories);

// =========================
// Utility Functions for Color Manipulation
// =========================

// Color Palette
const colors = {
  primary: '#2563EB',       // Blue-600
  secondary: '#3B82F6',     // Blue-500
  background: '#F9FAFB',    // Gray-50
  surface: '#FFFFFF',
  error: '#DC2626',         // Red-600
  onPrimary: '#FFFFFF',
  onBackground: '#111827',  // Gray-900
};

const hexToRgb = (hex: string): string => {
  hex = hex.replace('#', '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

// =========================
// Global Styles
// =========================

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
  
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    background-color: #ffffff;
    color: #333333;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden; /* Prevent body from scrolling */
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }
`;

const shine = keyframes`
  0% { background-position: -100% 0; }
  100% { background-position: 200% 0; }
`;

const float = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-2px); }
  100% { transform: translateY(0); }
`;

const shimmer = keyframes`
  0% { transform: translateX(-150%); }
  100% { transform: translateX(150%); }
`;

const Banner = styled.div`
  background: linear-gradient(
    135deg, 
    #4f46e5 0%, 
      #3b82f6 100%
  );
  padding: 14px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: 
    0 8px 16px rgba(0, 0, 0, 0.1),
    inset 0 2px 4px rgba(0, 0, 0, 0.05);
  
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.05) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0.05) 75%,
      transparent 75%
    );
    background-size: 8px 8px;
    opacity: 0.4;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    animation: ${shimmer} 4s infinite;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const BannerText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  z-index: 1;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.2px;

  svg {
    font-size: 1.25rem;
    color: #fef3c7;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    animation: ${float} 2s ease-in-out infinite;
  }
`;

const HighlightText = styled.span`
  color: #fef3c7;
  font-weight: 700;
`;

const BannerContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @media (min-width: 1920px) {
    max-width: 1800px;
  }
`;

// const float = keyframes`
//   0% { transform: translateY(0); }
//   50% { transform: translateY(-4px); }
//   100% { transform: translateY(0); }
// `;

const HeaderContainer = styled.div`
  display: none; // Hidden by default on desktop

  @media (max-width: 768px) {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: linear-gradient(135deg, #1e3a8a 0%, #3b82f6 100%);
    border-radius: 16px;
    width: 100%;
    margin-bottom: 24px;
    overflow: hidden;
    box-shadow: 
      0 12px 24px -4px rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px rgba(255, 255, 255, 0.1);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        45deg,
        transparent 25%,
        rgba(255, 255, 255, 0.05) 25%,
        rgba(255, 255, 255, 0.05) 50%,
        transparent 50%,
        transparent 75%,
        rgba(255, 255, 255, 0.05) 75%
      );
      background-size: 20px 20px;
      opacity: 0.3;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 200%;
      height: 100%;
      background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.1),
        transparent
      );
      animation: ${shimmer} 8s infinite linear;
    }

    @media (max-width: 480px) {
      flex-direction: column;
      gap: 16px;
      padding: 20px 16px;
      text-align: center;
    }
  }
`;

const Title = styled.h1`
  font-size: 1.7rem;
  font-weight: 800;
  color: white;
  margin: 0;
  position: relative;
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  letter-spacing: -0.02em;

  @media (max-width: 1024px) {
    font-size: 1.75rem;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
  z-index: 1;

  @media (max-width: 480px) {
    gap: 12px;
    flex-direction: column;
  }
`;

const Pill = styled.div<{ accent?: boolean }>`
  background: ${props => props.accent
    ? 'linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)'
    : 'rgba(255, 255, 255, 0.9)'};
  color: ${props => props.accent ? 'white' : '#1e293b'};
  padding: 12px 24px;
  border-radius: 100px;
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px ${props => props.accent ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'};
  transition: all 0.3s ease;
  animation: ${float} 3s infinite ease-in-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 
      0 6px 16px rgba(0, 0, 0, 0.12),
      inset 0 0 0 1px ${props => props.accent ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.08)'};
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 10px 20px;
  }
`;

// =========================
// Keyframes for animations
// =========================

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(16px);
  } 
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// =========================
// Styled Components
// =========================

// Fullscreen container
const FullscreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 24px 64px 120px 32px; // Reduced left padding from 64px to 32px
  animation: ${fadeIn} 0.8s ease-in;
  width: calc(100% - 344px);
  margin-left: auto;

  @media (max-width: 1024px) {
    padding: 32px 48px 120px 24px; // Adjusted for medium screens
  }

  @media (max-width: 800px) {
    padding: 24px 32px 120px 20px; // Adjusted for smaller screens
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    padding: 16px; // Single value applies to all sides on mobile
  }
`;

const PreviewButton = styled(Link)`
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
  color: ${colors.primary};
  border: 1px solid rgba(37, 99, 235, 0.3);
  padding: 10px 20px;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 
    0 2px 4px rgba(37, 99, 235, 0.05),
    0 1px 2px rgba(37, 99, 235, 0.1);

  &:hover {
    background: ${colors.primary};
    color: ${colors.onPrimary};
    border-color: ${colors.primary};
    transform: translateY(-1px);
    box-shadow: 
      0 4px 8px rgba(37, 99, 235, 0.15),
      0 2px 4px rgba(37, 99, 235, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 
      0 1px 2px rgba(37, 99, 235, 0.05),
      0 1px 1px rgba(37, 99, 235, 0.1);
  }

  &:focus {
    outline: none;
    box-shadow: 
      0 0 0 3px rgba(37, 99, 235, 0.2),
      0 1px 2px rgba(37, 99, 235, 0.05);
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 0.85rem;
    border-radius: 10px;
  }
`;

const CategoryHeader = styled.div`
  width: 100%; // Add this line
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const CategoryTitle = styled.h2`
  font-size: 1.3rem;
  font-weight: 700;
  margin-left: 0;
  margin-bottom: 4px;
  color: #1a1a1a;
  letter-spacing: -0.02em;
  line-height: 1.3;
  position: relative;
  padding-left: 16px;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 24px;
    background: #2563eb;
    border-radius: 2px;
  }

  @media (max-width: 1024px) {
    font-size: 1.4rem;
    padding-left: 14px;
    
    &::before {
      height: 22px;
    }
  }

  @media (max-width: 768px) {
    font-size: 1.3rem;
    padding-left: 12px;
    
    &::before {
      height: 20px;
    }
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
    padding-left: 10px;
    
    &::before {
      height: 18px;
    }
  }
`;

const shimmerEffect = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
`;

// Badge Component
const Badge = styled.span<{ backgroundColor: string }>`
 position: absolute;
  top: 16px;
  left: 16px;
  background: ${props => true
    ? 'linear-gradient(135deg, #22c55e, #16a34a)'
    : 'linear-gradient(135deg, #f97316, #ea580c)'};
  color: white;
  padding: 8px 16px;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 6px;
  z-index: 3;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  letter-spacing: 0.01em;

  svg {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    // animation: ${shimmerEffect} 2s infinite;
  }
`;

const MetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 3;
`;

const MetadataTag = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background: rgba(250, 204, 21, 0.15); // Yellow tint background
  padding: 3px 6px;
  border-radius: 6px;
  font-size: 0.7rem;
  font-weight: 500;
  color: white;
  backdrop-filter: blur(4px);
  white-space: nowrap;
  border: 1px solid rgba(250, 204, 21, 0.3); // Subtle yellow border
  
  svg {
    font-size: 0.75rem;
    opacity: 1;
    color: #facc15;
  }
`;

const OverlayContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2px 16px 16px 16px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.6) 50%,
    transparent 100%
  );
  backdrop-filter: blur(8px);
`;

// Selection Overlay
const SelectionOverlay = styled.div<{ selected: boolean }>`
  display: ${(props) => (props.selected ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 116, 162, 0.7);
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;
`;

// Bottom Navigation Bar
const BottomNav = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 24px;
  background-color: #ffffff;
  border-top: 1px solid #E5E7EB;
  z-index: 1000;

  @media (max-width: 480px) {
    padding: 8px 16px;
  }
`;

// Navigation Button
const NavButton = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? colors.primary : 'transparent')};
  color: ${(props) => (props.primary ? colors.onPrimary : colors.primary)};
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  border: ${(props) => (props.primary ? 'none' : `2px solid ${colors.primary}`)};
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
    props.primary ? colors.secondary : `${colors.primary}1A`};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.primary}33;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;

// =========================
// Styled Components for TemplateCategorySection
// =========================

const OwenContainer = styled.div`
  width: 100%;
  max-width: 100%; // Remove fixed width constraint
  padding-right: 24px; // Add padding to prevent touching the edge
  margin-left: -8px; 
  
  @media (max-width: 768px) {
    padding-right: 0;
  }
`;
// Wrapper for the Templates Grid and Arrows
const TemplatesGridWrapper = styled.div`
  position: relative;
`;

// Arrow Buttons
const Arrow = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 8px;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    display: none;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const LeftArrow = styled(Arrow)`
  left: 8px;
`;

const RightArrow = styled(Arrow)`
  right: 8px;
`;

const TemplateTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 4px;
  color: white;
`;

const fadeInScale = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

// Modify TemplatesGridStyled to respect container boundaries
const TemplatesGridStyled = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  overflow-x: ${(props) => (props.isMobile ? 'visible' : 'auto')};
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  max-width: 100%;
  padding-bottom: 16px; // Add padding for better visual spacing

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    flex: 0 0 auto;
  }

  ${(props) =>
    props.isMobile &&
    `
    & > * {
      margin-bottom: 16px;
    }
  `}
`;

// Update TemplateCardStyled to be more responsive
const TemplateCardStyled = styled.div<{ selected: boolean; isMobile: boolean }>`
  position: relative;
  background: linear-gradient(to bottom, #ffffff, #fafafa);
  border: 2px solid ${props => props.selected ? '#0066cc' : '#e5e7eb'};
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  min-width: ${props => props.isMobile ? '100%' : '280px'};
  max-width: ${props => props.isMobile ? '100%' : '280px'};
  margin-right: 16px;
  
  &:last-child {
    margin-right: 0;
  }

  @media (min-width: 1920px) {
    min-width: ${props => props.isMobile ? '100%' : '320px'};
    max-width: ${props => props.isMobile ? '100%' : '320px'};
  }
`;

const SideBarAndContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    min-height: 0; // Important for nested scrolling

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const PriceTag = styled.div`
  display: inline-flex;
  align-items: baseline;
  gap: 8px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(4px);
  padding: 6px 12px;
  border-radius: 8px;
  color: white;

  .current-price {
    font-size: 1.1rem;
    font-weight: 700;
    color: white;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .original-price {
    font-size: 0.875rem;
    text-decoration: line-through;
    opacity: 0.7;
  }
`;

// =========================
// TemplateCategorySection Component
// =========================

interface TemplateCategorySectionProps {
  category: {
    name: string;
    bundlePrice: number;
    templates: Template[];
  };
  selectedTemplates: string[];
  handleTemplateSelect: (templateId: string) => void;
  getCategoryNameByTemplateId: (templateId: string) => string;
  handleToggleSelectAllInCategory: (categoryName: string) => void;
  hasDoneFreeOrder: boolean;
}

const TemplateCategorySection: React.FC<TemplateCategorySectionProps> = ({
  category,
  selectedTemplates,
  handleTemplateSelect,
  getCategoryNameByTemplateId,
  handleToggleSelectAllInCategory,
  hasDoneFreeOrder,
}) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      console.log(`Window width: ${window.innerWidth}px`);
    };

    window.addEventListener('resize', handleResize);

    // Initial log when the component mounts
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
      updateArrows();
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    updateArrows();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const updateArrows = () => {
    const grid = gridRef.current;
    if (grid && !isMobile) {
      const { scrollWidth, clientWidth } = grid;
      setShowRightArrow(scrollWidth > clientWidth);
      setShowLeftArrow(false);
    } else {
      setShowLeftArrow(false);
      setShowRightArrow(false);
    }
  };

  const handleScroll = () => {
    const grid = gridRef.current;
    if (grid && !isMobile) {
      const { scrollLeft, scrollWidth, clientWidth } = grid;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scrollAmount = 300;

  const scrollLeft = () => {
    const grid = gridRef.current;
    if (grid) {
      grid.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    const grid = gridRef.current;
    if (grid) {
      grid.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <OwenContainer>
      <CategoryHeader>
        <CategoryTitle>{category.name}</CategoryTitle>
        {/* {category.name === 'LinkedIn Professional' && (
          <PreviewButton to="/order-example">
            See Example Order
          </PreviewButton>
        )} */}
      </CategoryHeader>
      <TemplatesGridWrapper>
        {/* Left Arrow */}
        <LeftArrow onClick={scrollLeft} visible={showLeftArrow}>
          <FaChevronLeft />
        </LeftArrow>

        {/* Right Arrow */}
        <RightArrow onClick={scrollRight} visible={showRightArrow}>
          <FaChevronRight />
        </RightArrow>

        <TemplatesGridStyled ref={gridRef} onScroll={handleScroll} isMobile={isMobile}>
          {category.templates.map((template) => {
            const isSelected = selectedTemplates.includes(template.id);
            const isDiscounted = template.isDiscounted || false;
            const isFree = template.isPaid === false && !hasDoneFreeOrder;

            return (
              <TemplateCardStyled
                key={template.id}
                selected={isSelected}
                onClick={() => handleTemplateSelect(template.id)}
                tabIndex={0}
                aria-selected={isSelected}
                role="button"
                aria-label={`Select ${template.title} template`}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleTemplateSelect(template.id);
                  }
                }}
                isMobile={isMobile}
              >
                {/* Selection Overlay */}
                <SelectionOverlay selected={isSelected}>50 Photos</SelectionOverlay>

                {(isFree || isDiscounted) && (
                  <Badge backgroundColor={isFree ? '#28a745' : '#FF5722'}>
                    {isFree ? (
                      <>
                        <FaGift style={{ marginRight: '4px' }} />
                        Free for first time user
                      </>
                    ) : (
                      <>
                        <FaTag style={{ marginRight: '4px' }} />
                        Popular
                      </>
                    )}
                  </Badge>
                )}

                {/* Template Image */}
                <div
                  style={{
                    width: '100%',
                    height: '0',
                    paddingBottom: '100%',
                    backgroundImage: `url(${template.afterImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                />

                <OverlayContainer>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <div>
                      <TemplateTitle>{template.title}</TemplateTitle>
                      {!isFree && (
                        <PriceTag>
                          <span className="current-price">
                            ${(isDiscounted
                              ? category.name === 'LinkedIn Professional'
                                ? 9.99
                                : 5.99 * 0.8
                              : category.name === 'LinkedIn Professional'
                                ? 9.99
                                : 5.99).toFixed(2)}
                          </span>
                          <span className="original-price">
                            ${(isDiscounted
                              ? (category.name === 'LinkedIn Professional' ? 9.99 : 5.99) / 0.8
                              : category.name === 'LinkedIn Professional'
                                ? 15.99
                                : 9.99).toFixed(2)}
                          </span>
                        </PriceTag>
                      )}
                    </div>

                    <MetadataContainer>
                      <MetadataTag>
                        <FaImage />
                        50 Photos
                      </MetadataTag>
                      <MetadataTag>
                        <FaExpand />
                        1024×1024
                      </MetadataTag>
                    </MetadataContainer>
                  </div>
                </OverlayContainer>
              </TemplateCardStyled>
            );
          })}
        </TemplatesGridStyled>
      </TemplatesGridWrapper>
    </OwenContainer>
  );
};

// =========================
// Interface for props
// =========================

interface TemplateSelectionPageProps {
  onSelectTemplates: (templates: string[]) => void;
}

// =========================
// Template Selection Page Component
// =========================

const TemplateSelectionPage: React.FC<TemplateSelectionPageProps> = ({
  onSelectTemplates,
}) => {
  const navigate = useNavigate();
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [user] = useAuthState(auth);
  const [hasDoneFreeOrder, setHasDoneFreeOrder] = useState<boolean>(false);

  // State for animated photo count
  const [animatedPhotos, setAnimatedPhotos] = useState<number>(0);
  const photosRef = useRef<number>(0);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  // Add this new handler function
  const handleCategoryToggle = (category: string) => {
    setSelectedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const filteredCategories = templateCategories.filter(category => {
    // If no categories are selected, show all categories
    if (selectedCategories.length === 0) return true;

    // If search term exists, filter by that too
    if (searchTerm) {
      return (
        selectedCategories.includes(category.name) &&
        (category.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          category.templates.some(template =>
            template.title.toLowerCase().includes(searchTerm.toLowerCase())
          ))
      );
    }

    // Otherwise just filter by selected categories
    return selectedCategories.includes(category.name);
  });

  useEffect(() => {
    const checkIfUserHasDoneFreeOrder = async () => {
      if (user) {
        try {
          const freeOrderDocRef = doc(db, 'freeOrders', user.uid);
          const freeOrderDoc = await getDoc(freeOrderDocRef);
          if (freeOrderDoc.exists()) {
            setHasDoneFreeOrder(true);
          } else {
            setHasDoneFreeOrder(false);
          }
        } catch (error) {
          console.error('Error checking free order status:', error);
        }
      }
    };

    checkIfUserHasDoneFreeOrder();
  }, [user]);

  useEffect(() => {
    const calculateTargetPhotos = () => {
      return selectedTemplates.reduce((total, templateId) => {
        const template = templateService.findTemplateById(templateId);
        if (!template) return total;

        const isFreeForUser = template.isPaid === false && !hasDoneFreeOrder;
        return total + (isFreeForUser ? 50 : 50);
      }, 0);
    };

    const targetPhotos = calculateTargetPhotos();
    const duration = 500; // duration of animation in ms
    const frameRate = 60; // frames per second
    const totalFrames = (duration / 1000) * frameRate;
    const increment = (targetPhotos - photosRef.current) / totalFrames;
    let frame = 0;

    const animate = () => {
      frame++;
      const newCount = Math.round(photosRef.current + increment);
      setAnimatedPhotos(newCount);
      photosRef.current = newCount;
      if (frame < totalFrames) {
        requestAnimationFrame(animate);
      } else {
        setAnimatedPhotos(targetPhotos);
        photosRef.current = targetPhotos;
      }
    };

    animate();
  }, [selectedTemplates, hasDoneFreeOrder]);

  const handleTemplateSelect = (templateId: string) => {
    const isSelected = selectedTemplates.includes(templateId);

    if (isSelected) {
      setSelectedTemplates(selectedTemplates.filter((id) => id !== templateId));
    } else {
      setSelectedTemplates([...selectedTemplates, templateId]);
    }

    const template = templateService.findTemplateById(templateId);

    if (analytics && template) {
      logEvent(analytics, 'template_selected', {
        template_id: template.id,
        template_title: template.title,
        is_paid: template.isPaid ? 'yes' : 'no',
        is_discounted: template.isDiscounted ? 'yes' : 'no',
        category_name: getCategoryNameByTemplateId(template.id),
      });
    }
  };

  const getCategoryNameByTemplateId = (templateId: string): string => {
    for (const category of templateCategories) {
      if (category.templates.some((template) => template.id === templateId)) {
        return category.name;
      }
    }
    return 'Unknown';
  };

  // New function to handle select/deselect all templates in a category
  const handleToggleSelectAllInCategory = (categoryName: string) => {
    const category = templateCategories.find((cat) => cat.name === categoryName);
    if (!category) return;

    const categoryTemplateIds = category.templates.map((template) => template.id);

    const allSelected = categoryTemplateIds.every((id) =>
      selectedTemplates.includes(id)
    );

    if (allSelected) {
      // Deselect all templates in the category
      setSelectedTemplates(selectedTemplates.filter((id) => !categoryTemplateIds.includes(id)));
    } else {
      // Select all templates in the category
      setSelectedTemplates([...new Set([...selectedTemplates, ...categoryTemplateIds])]);
    }
  };

  const handleNext = () => {
    if (selectedTemplates.length > 0) {
      onSelectTemplates(selectedTemplates);

      // Calculate total price
      const totalPrice = calculateTotalPriceForTemplates(selectedTemplates);

      // Log event when 'Next' button is clicked
      if (analytics) {
        logEvent(analytics, 'step1_next_button_clicked', {
          selected_templates_count: selectedTemplates.length,
          total_price: totalPrice.toFixed(2),
        });
      }

      navigate('/upload-photos');
    } else {
      toast.warn('Please select at least one template to proceed.', {
        position: 'top-center',
        autoClose: 3000,
      });
    }
  };

  const calculateTotalPriceForTemplates = (templateIds: string[]) => {
    let totalPrice = 0;
    const countedTemplates = new Set<string>();

    templateCategories.forEach((category) => {
      const categoryTemplateIds = category.templates.map((template) => template.id);
      const selectedTemplatesInCategory = categoryTemplateIds.filter((id) =>
        templateIds.includes(id)
      );

      selectedTemplatesInCategory.forEach((id) => {
        if (!countedTemplates.has(id)) {
          const template = templateService.findTemplateById(id);
          if (!template) return;

          const isFreeForUser = template.isPaid === false && !hasDoneFreeOrder;
          let price = category.name === 'LinkedIn Professional' ? 9.99 : 5.99;

          if (isFreeForUser) {
            // Free template, no cost added
          } else if (template.isDiscounted) {
            totalPrice += price * 0.75;
          } else {
            totalPrice += price;
          }
          countedTemplates.add(id);
        }
      });
    });

    return totalPrice;
  };

  // Calculate total price
  const totalPrice = calculateTotalPriceForTemplates(selectedTemplates);

  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <FullscreenContainer>
        <Navbar
          navItems={[
            {
              label: 'Home',
              to: '/',
            },
          ]}
          showSignOutButton={!!user}
          currentStep={1}
        />


        <Banner>
          <BannerContent>
            <BannerText>
              <FaBullhorn />
              Winter Sale! <HighlightText>All portrait packs 40% off</HighlightText>
            </BannerText>
          </BannerContent>
        </Banner>

        <SideBarAndContentContainer>
          <TemplateSidebar
            categories={templateCategories}
            selectedCategories={selectedCategories}
            onCategoryToggle={handleCategoryToggle}
            searchTerm={searchTerm}
            onSearchChange={setSearchTerm}
            selectedTemplatesCount={selectedTemplates.length}
            totalPrice={totalPrice}
          />

          <ContentContainer>
            <HeaderContainer>
              <Title>50 Headshots per pack</Title>
              <StatsContainer>
                <Pill>
                  {animatedPhotos} Portraits
                </Pill>
                <Pill accent>
                  ${totalPrice.toFixed(2)}
                </Pill>
              </StatsContainer>
            </HeaderContainer>

            {/* Templates by Category */}
            <div>
              {filteredCategories.map((category) => (
                <TemplateCategorySection
                  key={category.name}
                  category={category}
                  selectedTemplates={selectedTemplates}
                  handleTemplateSelect={handleTemplateSelect}
                  handleToggleSelectAllInCategory={handleToggleSelectAllInCategory}
                  getCategoryNameByTemplateId={getCategoryNameByTemplateId}
                  hasDoneFreeOrder={hasDoneFreeOrder}
                />
              ))}
            </div>
          </ContentContainer>
        </SideBarAndContentContainer>

        {/* Bottom Navigation */}
        <BottomNav>
          <NavButton
            primary
            onClick={handleNext}
            aria-label="Proceed to Next Step"
            disabled={selectedTemplates.length === 0}
          >
            Next
          </NavButton>
        </BottomNav>
      </FullscreenContainer>
    </>
  );
};

export default TemplateSelectionPage;
