import React from 'react';
import styled from 'styled-components';
import { FaPlus, FaShoppingCart, FaTrash, FaSearch, FaCircle, FaGift, FaClock, } from 'react-icons/fa';

export type Model = {
    id: string;
    trainingStatus: string;
    modelName: string;
    storagePath: string;
    timestamp: any;
    userEmail: string;
    triggerWord: string;
};

const theme = {
    colors: {
        background: '#ffffff',
        surface: '#f8fafc',
        primary: '#3b82f6',
        primaryLight: '#60a5fa',
        primaryDark: '#2563eb',
        secondary: '#64748b',
        accent: '#7c3aed',
        success: '#10b981',
        warning: '#f59e0b',
        danger: '#ef4444',
        text: {
            primary: '#1e293b',
            secondary: '#64748b',
            light: '#94a3b8'
        },
        border: '#e2e8f0',
        hover: '#f1f5f9'
    },
    shadows: {
        sm: '0 1px 2px rgba(0, 0, 0, 0.05)',
        md: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
        lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1)'
    }
};

const SidebarContainer = styled.aside`
    width: 320px;
    height: 100%;
    background: ${theme.colors.background};
    border-right: 1px solid ${theme.colors.border};
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: width 0.3s ease;

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
    }
`;

const ScrollableContent = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 24px 20px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: ${theme.colors.text.light};
        border-radius: 3px;
    }
`;

const Section = styled.section`
    margin-bottom: 32px;
`;

const SectionHeader = styled.div`
    margin-bottom: 16px;
`;

const SectionTitle = styled.h2`
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.text.secondary};
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: 0;
`;

const TokenCard = styled.div`
    background: ${theme.colors.surface};
    border-radius: 12px;
    padding: 16px;
    box-shadow: ${theme.shadows.sm};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
`;

const TokenInfo = styled.div`
    flex: 1;
`;

const TokenAmount = styled.div`
    font-size: 24px;
    font-weight: 700;
    color: ${theme.colors.primary};
    line-height: 1;
`;

const TokenLabel = styled.div`
    font-size: 12px;
    color: ${theme.colors.text.secondary};
    margin-top: 4px;
`;

const Button = styled.button<{ variant?: 'primary' | 'secondary' }>`
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.2s ease;

    ${props => props.variant === 'primary' ? `
        background: linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.primaryDark});
        color: white;
        &:hover {
            transform: translateY(-1px);
            box-shadow: ${theme.shadows.md};
        }
    ` : `
        background: ${theme.colors.surface};
        color: ${theme.colors.text.primary};
        border: 1px solid ${theme.colors.border};
        &:hover {
            background: ${theme.colors.hover};
        }
    `}
`;

const SearchContainer = styled.div`
    position: relative;
    margin-bottom: 24px;
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 12px 16px 12px 40px;
    border: 1px solid ${theme.colors.border};
    border-radius: 8px;
    font-size: 14px;
    color: ${theme.colors.text.primary};
    background: ${theme.colors.background};
    transition: all 0.2s ease;

    &:focus {
        outline: none;
        border-color: ${theme.colors.primary};
        box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
    }

    &::placeholder {
        color: ${theme.colors.text.light};
    }
`;

const SearchIconWrapper = styled.div`
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: ${theme.colors.text.light};
    pointer-events: none;
`;

const ModelGrid = styled.div`
    display: grid;
    gap: 16px;
`;

const StarterLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.colors.primary};
  margin-top: 4px;
  
  svg {
    color: ${theme.colors.accent};
  }
`;


const ModelMainContent = styled.div`
    padding: 16px;
    border-bottom: 1px solid ${theme.colors.border};
`;

const ModelMetaContent = styled.div`
    padding: 12px 16px;
    background: ${theme.colors.surface};
    border-radius: 0 0 12px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ModelStats = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    color: ${theme.colors.text.secondary};
    font-size: 13px;

    > div {
        display: flex;
        align-items: center;
        gap: 4px;
    }
`;

const MODEL_COLORS = [
    '#FF6B6B', // coral red
    '#4ECDC4', // turquoise
    '#45B7D1', // ocean blue
    '#96CEB4', // sage green
    '#9D94FF', // soft purple
    '#FFB6B9', // soft pink
    '#7986CB', // indigo
    '#80DEEA', // cyan
    '#26A69A', // teal
    '#FF8A65'  // deep coral
];


const StatusBadge = styled.span<{ status: string }>`
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 6px 10px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    
    ${props => {
        const status = props.status;
        if (status === 'complete') {
            return `
                background: rgba(16, 185, 129, 0.1);
                color: ${theme.colors.success};
            `;
        } else if (status === 'inProgress') {
            return `
                background: rgba(245, 158, 11, 0.1);
                color: ${theme.colors.warning};
            `;
        }
        return `
            background: rgba(239, 68, 68, 0.1);
            color: ${theme.colors.danger};
        `;
    }}
`;

const StatusIndicator = styled.div<{ status: string }>`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: relative;
    
    ${props => {
        const status = props.status;
        if (status === 'complete') {
            return `
                background: ${theme.colors.success};
                box-shadow: 0 0 0 4px rgba(16, 185, 129, 0.15);
            `;
        } else if (status === 'inProgress') {
            return `
                background: ${theme.colors.warning};
                box-shadow: 0 0 0 4px rgba(245, 158, 11, 0.15);
                &::after {
                    content: '';
                    position: absolute;
                    top: -4px;
                    left: -4px;
                    right: -4px;
                    bottom: -4px;
                    border-radius: 50%;
                    border: 2px solid ${theme.colors.warning};
                    animation: pulse 1.5s ease-in-out infinite;
                }
                @keyframes pulse {
                    0% { transform: scale(1); opacity: 1; }
                    50% { transform: scale(1.2); opacity: 0.5; }
                    100% { transform: scale(1); opacity: 1; }
                }
            `;
        }
        return `
            background: ${theme.colors.danger};
            box-shadow: 0 0 0 4px rgba(239, 68, 68, 0.15);
        `;
    }}
`;

const ModelStatus = styled.div<{ status: string }>`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 13px;
    font-weight: 500;
    padding: 6px 12px;
    border-radius: 20px;
    background: ${theme.colors.background};
    border: 1px solid ${theme.colors.border};
    margin-left: auto;
    
    ${props => {
        const status = props.status;
        if (status === 'complete') {
            return `color: ${theme.colors.success};`;
        } else if (status === 'inProgress') {
            return `color: ${theme.colors.warning};`;
        }
        return `color: ${theme.colors.danger};`;
    }}
`;

interface ModelCardProps {
    model: Model;
    selected: boolean;
    onSelect: () => void;
    onDelete: () => void;
}

const ModelItem = styled.div<{ selected: boolean; isStarter?: boolean }>`
    background: ${props => props.isStarter ?
        `linear-gradient(135deg, ${theme.colors.background}, ${theme.colors.surface})` :
        theme.colors.background};
    border: 1px solid ${props => {
        if (props.isStarter) return theme.colors.accent;
        return props.selected ? theme.colors.primary : theme.colors.border;
    }};
    border-radius: 16px;
    padding: 20px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${props => props.selected && !props.isStarter && `
        background: linear-gradient(
            135deg,
            ${theme.colors.surface},
            ${theme.colors.background}
        );
        box-shadow: ${theme.shadows.md};
    `}

    &:hover {
        transform: translateY(-2px);
        box-shadow: ${theme.shadows.md};
    }
`;

const TopRow = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
`;

const BottomRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
`;

const StarterBadge = styled.div`
    background: ${theme.colors.accent};
    color: white;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(135deg, rgba(255,255,255,0.2), rgba(255,255,255,0));
    }
`;

const ColorSquare = styled.div<{ color: string }>`
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: ${props => props.color};
    position: relative;
    flex-shrink: 0;
    
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(135deg, rgba(255,255,255,0.2), rgba(255,255,255,0));
        border-radius: inherit;
    }
`;

const ModelNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const ModelName = styled.h3`
    font-size: 17px;
    font-weight: 600;
    background: linear-gradient(
        to right,
        ${theme.colors.text.primary} 0%,
        ${theme.colors.text.secondary} 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    line-height: 1.3;
    letter-spacing: -0.01em;
`;

const ModelSubtext = styled.div`
    font-size: 13px;
    color: ${theme.colors.text.light};
    font-weight: 500;
`;

const DeleteButton = styled.button`
    background: none;
    border: none;
    color: ${theme.colors.text.light};
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;
    transition: all 0.2s ease;
    line-height: 0;

    &:hover {
        color: ${theme.colors.danger};
        background: rgba(239, 68, 68, 0.1);
    }
`;

const ModelCard: React.FC<ModelCardProps> = ({ model, selected, onSelect, onDelete }) => {
    const colorIndex = model.id.split('').reduce((acc: number, char: string) => acc + char.charCodeAt(0), 0) % MODEL_COLORS.length;
    const modelColor = MODEL_COLORS[colorIndex];
    const isStarterModel = model.modelName === "PhotoOp Cat";

    const getStatusText = (status: string) => {
        switch (status) {
            case 'complete': return 'Ready';
            case 'inProgress': return 'Training';
            default: return 'Error';
        }
    };

    return (
        <ModelItem selected={selected} onClick={onSelect} isStarter={isStarterModel}>
            <TopRow>
                <ModelNameWrapper>
                    <ModelName>{model.modelName}</ModelName>
                    <ModelSubtext>Custom model</ModelSubtext>
                </ModelNameWrapper>
                <ModelStatus status={model.trainingStatus}>
                    <StatusIndicator status={model.trainingStatus} />
                    {getStatusText(model.trainingStatus)}
                </ModelStatus>
            </TopRow>

            <BottomRow>
                {isStarterModel ? (
                    <StarterBadge>
                        ✨ Starter Model
                    </StarterBadge>
                ) : (
                    <ColorSquare color={modelColor} />
                )}
                {!isStarterModel && (
                    <DeleteButton
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete();
                        }}
                    >
                        <FaTrash size={16} />
                    </DeleteButton>
                )}
            </BottomRow>
        </ModelItem>
    );
};

interface SidebarProps {
    user: any;
    tokens: number | null;
    selectedModel: Model | null;
    models: Model[];
    searchTerm: string;
    onSearchChange: (term: string) => void;
    onModelSelect: (model: Model) => void;
    onDeleteModel: (modelId: string, storagePath: string, trainingStatus: string) => void;
    onAddNewModel: () => void;
    onPurchaseTokens: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({
    user,
    tokens,
    selectedModel,
    models,
    searchTerm,
    onSearchChange,
    onModelSelect,
    onDeleteModel,
    onAddNewModel,
    onPurchaseTokens
}) => {
    const filteredModels = models.filter((model) =>
        model.modelName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        model.triggerWord.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <SidebarContainer>
            <ScrollableContent>
                <Section>
                    <SectionHeader>
                        <SectionTitle>Token Balance</SectionTitle>
                    </SectionHeader>
                    <TokenCard>
                        <TokenInfo>
                            <TokenAmount>{tokens ?? '...'}</TokenAmount>
                            <TokenLabel>Available Tokens</TokenLabel>
                        </TokenInfo>
                        <Button variant="primary" onClick={onPurchaseTokens} style={{ width: 'auto' }}>
                            <FaShoppingCart />
                        </Button>
                    </TokenCard>
                </Section>

                <Section>
                    <SectionHeader>
                        <SectionTitle>Your models</SectionTitle>
                    </SectionHeader>
                    <Button variant="primary" onClick={onAddNewModel} style={{ marginBottom: '24px' }}>
                        <FaPlus /> Create New Model
                    </Button>

                    {models.length > 2 && (
                        <SearchContainer>
                            <SearchIconWrapper>
                                <FaSearch size={16} />
                            </SearchIconWrapper>
                            <SearchInput
                                type="text"
                                placeholder="Search models..."
                                value={searchTerm}
                                onChange={(e) => onSearchChange(e.target.value)}
                            />
                        </SearchContainer>
                    )}

                    <ModelGrid>
                        {filteredModels.map((model) => (
                            <ModelCard
                                key={model.id}
                                model={model}
                                selected={selectedModel?.id === model.id}
                                onSelect={() => onModelSelect(model)}
                                onDelete={() => onDeleteModel(model.id, model.storagePath, model.trainingStatus)}
                            />
                        ))}
                        {filteredModels.length === 0 && (
                            <div style={{ textAlign: 'center', color: theme.colors.text.secondary }}>
                                No matching generators found
                            </div>
                        )}
                    </ModelGrid>
                </Section>
            </ScrollableContent>
        </SidebarContainer>
    );
};

export default Sidebar;