import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaInfoCircle, FaMagic, FaSpinner, FaDownload, FaImage } from 'react-icons/fa';
import { auth, db } from '../firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, runTransaction, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';

const theme = {
  colors: {
    background: '#ffffff',
    surface: '#f8fafc',
    primary: '#3b82f6',
    primaryLight: '#60a5fa',
    primaryDark: '#2563eb',
    secondary: '#64748b',
    accent: '#7c3aed',
    success: '#10b981',
    warning: '#f59e0b',
    danger: '#ef4444',
    text: {
      primary: '#1e293b',
      secondary: '#64748b',
      light: '#94a3b8'
    },
    border: '#e2e8f0',
    hover: '#f1f5f9'
  },
  shadows: {
    sm: '0 1px 2px rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1)'
  }
};

const DownloadButton = styled.button`
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: ${theme.colors.primary};
  color: white;
  border: none;
  border-radius: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(37, 99, 235, 0.2);

  &:hover {
    background-color: ${theme.colors.primaryDark};
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(37, 99, 235, 0.25);
  }

  svg {
    font-size: 1.1em;
  }
`;

const Container = styled.div`
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    padding: 40px;
    // background: ${theme.colors.background};
    
    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
        padding: 24px;
        gap: 32px;
    }
`;

const PromptSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 500px;
`;

const Card = styled.div`
    background: ${theme.colors.surface};
    border-radius: 16px;
    padding: 32px;
    box-shadow: ${theme.shadows.sm};
    border: 1px solid ${theme.colors.border};
    height: 100%;
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: ${theme.shadows.md};
    }
`;

const ModelHeader = styled.div`
    margin-bottom: 32px;
`;

const ModelTitle = styled.h2`
    font-size: 28px;
    font-weight: 700;
    background: linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.accent});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 0 12px 0;
`;

const ModelDescription = styled.p`
    font-size: 15px;
    color: ${theme.colors.text.secondary};
    margin: 0;
    line-height: 1.6;
`;

const TokenAlert = styled.div<{ variant?: 'warning' | 'info' }>`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px 20px;
    margin-bottom: 24px;
    border-radius: 12px;
    background: ${props =>
    props.variant === 'warning'
      ? 'rgba(239, 68, 68, 0.05)'
      : 'rgba(59, 130, 246, 0.05)'};
    border: 1px solid ${props =>
    props.variant === 'warning'
      ? 'rgba(239, 68, 68, 0.2)'
      : 'rgba(59, 130, 246, 0.2)'};
    color: ${props =>
    props.variant === 'warning'
      ? theme.colors.danger
      : theme.colors.primary};
    font-size: 14px;
    line-height: 1.5;
    transition: all 0.2s ease;

    &:hover {
        transform: translateY(-1px);
    }
`;

const PromptInput = styled.textarea`
    width: 100%;
    min-height: 140px;
    padding: 20px;
    border: 2px solid ${theme.colors.border};
    border-radius: 12px;
    background: ${theme.colors.background};
    color: ${theme.colors.text.primary};
    font-size: 16px;
    line-height: 1.6;
    resize: vertical;
    transition: all 0.2s ease;

    &:focus {
        outline: none;
        border-color: ${theme.colors.primary};
        box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.1);
    }

    &::placeholder {
        color: ${theme.colors.text.light};
    }
`;

const GenerateButton = styled.button<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 18px 32px;
    margin-top: 24px;
    background: ${props => props.disabled
    ? theme.colors.text.light
    : `linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.accent})`};
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 600;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    transition: all 0.3s ease;

    &:not(:disabled):hover {
        transform: translateY(-2px);
        box-shadow: ${theme.shadows.lg};
    }
`;


const BaseBadge = styled.div`
    position: absolute;
    padding: 12px 16px;
    background: rgba(255, 255, 255, 0.98);
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.colors.text.primary};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07), 
                0 12px 20px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.7);
    transition: all 0.2s ease;
    
    svg {
        color: ${theme.colors.primary};
        font-size: 16px;
    }
`;

const ImagePreviewSection = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  background: linear-gradient(
    165deg,
    ${theme.colors.background} 0%,
    ${theme.colors.surface} 100%
  );
  border-radius: 24px;
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.05),
    0 10px 15px -3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const GeneratedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Placeholder = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.98);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const SpinnerIcon = styled(FaSpinner)`
  font-size: 48px;
  color: ${theme.colors.primary};
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    100% { transform: rotate(360deg); }
  }
`;

const StatusBadge = styled.div`
  padding: 12px 24px;
  background: ${theme.colors.background};
  border: 1px solid ${theme.colors.border};
  border-radius: 12px;
  color: ${theme.colors.text.primary};
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const Countdown = styled.div`
  font-size: 14px;
  color: ${theme.colors.text.secondary};
`;

const InstructionalOverlay = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 14px 20px;
  background: rgba(255, 255, 255, 0.98);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.text.primary};
  z-index: 10;
  backdrop-filter: blur(8px);
  box-shadow: 
    0 4px 6px rgba(0, 0, 0, 0.03),
    0 10px 15px rgba(0, 0, 0, 0.05);
  
  /* Subtle gradient background */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.98),
    rgba(252, 252, 252, 0.98)
  );

  /* Animation */
  animation: fadeIn 0.3s ease-out;
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-8px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Hover effect */
  transition: all 0.2s ease;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 
      0 6px 8px rgba(0, 0, 0, 0.04),
      0 12px 20px rgba(0, 0, 0, 0.06);
  }
`;

const InstructionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: ${theme.colors.primary};
  border-radius: 8px;
  color: white;
  font-size: 14px;
`;

const PlaceholderTooltip = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  padding: 12px 16px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  z-index: 10;
  backdrop-filter: blur(4px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const PhotoOpBadge = styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;
  padding: 8px 16px;
  background: white;
  color: ${theme.colors.text.primary};
  border-radius: 20px;
  font-size: 13px;
  font-weight: 600;
  z-index: 10;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  gap: 6px;
  letter-spacing: 0.5px;
  
  /* Add a subtle gradient background */
  background: linear-gradient(135deg, #fff, #f8f9fa);
  
  /* Add a hover effect */
  transition: all 0.2s ease;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  }
`;

const CatIcon = styled.span`
  font-size: 12px;
  color: ${theme.colors.primary};
`;

type Model = {
  id: string;
  modelName: string;
  storagePath: string;
  timestamp: any;
  userEmail: string;
  trainingStatus?: string;
  trainingProgress?: number;
  triggerWord: string;
};

interface ModelQueryUIProps {
  selectedModel: Model;
  prompt: string;
  setPrompt: (value: string) => void;
  handleGenerateImage: () => void;
  isLoadingImages: boolean;
  generatedImages: string[];
}

const ModelQueryUI: React.FC<ModelQueryUIProps> = ({
  selectedModel,
  prompt,
  setPrompt,
  handleGenerateImage,
  isLoadingImages,
  generatedImages,
}) => {
  const [user] = useAuthState(auth);
  const [tokens, setTokens] = useState<number | null>(null);
  const [loadingTokens, setLoadingTokens] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(0);
  const [placeholderImages, setPlaceholderImages] = useState<string[]>([]);
  const [selectedPlaceholder, setSelectedPlaceholder] = useState<string | null>(null);

  // Track Firestore status of the Flux model for this user
  const [fluxStatus, setFluxStatus] = useState<{
    is_base_model_loaded: boolean;
    status_description: string;
    eta_seconds: number;
  }>({
    is_base_model_loaded: false,
    status_description: '',
    eta_seconds: 0
  });

  const fetchPlaceholderImages = async () => {
    try {
      const storage = getStorage();
      const placeholdersRef = ref(storage, 'placeholder_custom_images');
      const result = await listAll(placeholdersRef);

      const urls = await Promise.all(
        result.items.map(async (item) => {
          return await getDownloadURL(item);
        })
      );

      setPlaceholderImages(urls);
      // Select a random placeholder image
      const randomIndex = Math.floor(Math.random() * urls.length);
      setSelectedPlaceholder(urls[randomIndex]);
    } catch (error) {
      console.error('Error fetching placeholder images:', error);
    }
  };

  const downloadImage = async (url: string, filename: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading image:', error);
      alert('Failed to download image. Please try again.');
    }
  };

  const resetFluxStatus = () => {
    setFluxStatus({
      is_base_model_loaded: false,
      status_description: 'Looking for active worker',
      eta_seconds: 0
    });
    setCountdown(300);
  };

  useEffect(() => {
    fetchPlaceholderImages();
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isLoadingImages) {
      // If Firestore has an eta, use that, otherwise keep using current countdown
      if (fluxStatus.eta_seconds > 0) {
        setCountdown(fluxStatus.eta_seconds);
      }

      timer = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [fluxStatus.eta_seconds, isLoadingImages]);

  // Clear prompt when changing models
  useEffect(() => {
    setPrompt('');
  }, [selectedModel, setPrompt]);

  // Listen to flux_status doc for changes
  useEffect(() => {
    if (user?.email) {
      const fluxDocRef = doc(db, 'flux_status', user.email);
      const unsubscribe = onSnapshot(
        fluxDocRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.data();
            setFluxStatus({
              is_base_model_loaded: data.is_base_model_loaded ?? false,
              status_description: data.status_description ?? '',
              eta_seconds: data.eta_seconds ?? 0
            });
          }
        },
        (error) => {
          console.error('Error fetching Flux model status:', error);
        }
      );
      return () => unsubscribe();
    }
  }, [user]);

  // Fetch token balance in real-time
  useEffect(() => {
    if (user?.email) {
      setLoadingTokens(true);
      const tokenDocRef = doc(db, 'userTokens', user.email);
      const unsubscribe = onSnapshot(
        tokenDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            setTokens(docSnapshot.data().tokens);
          } else {
            setTokens(0);
          }
          setLoadingTokens(false);
        },
        (error) => {
          console.error('Error fetching token balance:', error);
          setTokens(null);
          setLoadingTokens(false);
        }
      );

      return () => unsubscribe();
    }
  }, [user]);

  // Deduct a token after an image has been generated
  useEffect(() => {
    const deductToken = async () => {
      if (generatedImages.length > 0 && user?.email) {
        try {
          const userTokenDocRef = doc(db, 'userTokens', user.email);
          await runTransaction(db, async (transaction) => {
            const tokenDoc = await transaction.get(userTokenDocRef);
            if (!tokenDoc.exists()) {
              throw new Error('Token document does not exist.');
            }

            const currentTokens = tokenDoc.data().tokens;
            if (currentTokens < 1) {
              throw new Error('Insufficient tokens.');
            }

            transaction.update(userTokenDocRef, {
              tokens: currentTokens - 1,
              lastUpdated: serverTimestamp(),
            });
          });
        } catch (error: any) {
          console.error('Error deducting token:', error);
          alert(error.message || 'Failed to deduct token. Please try again.');
        }
      }
    };

    deductToken();
  }, [generatedImages, user]);

  return (
    <Container>
      <PromptSection>
        <Card>
          <ModelHeader>
            <ModelTitle>{selectedModel.modelName}</ModelTitle>
            <ModelDescription>
              Include <strong>{selectedModel.triggerWord}</strong> in your prompt to have them appear in the image.
            </ModelDescription>
          </ModelHeader>

          {loadingTokens ? (
            <TokenAlert>
              <FaInfoCircle />
              <span>Loading token balance...</span>
            </TokenAlert>
          ) : tokens === null || tokens <= 0 ? (
            <TokenAlert variant="warning">
              <FaInfoCircle />
              <span>You need tokens to generate images. Please purchase more tokens to continue.</span>
            </TokenAlert>
          ) : (
            <TokenAlert>
              <FaInfoCircle />
              <span>Generating an image costs 1 token. You have {tokens} tokens remaining.</span>
            </TokenAlert>
          )}

          <PromptInput
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder={`Try something like: "${selectedModel.triggerWord} exploring a magical forest at sunset"`}
            disabled={tokens === null || tokens <= 0}
          />

          <GenerateButton
            onClick={() => {
              resetFluxStatus()
              handleGenerateImage()
            }}
            disabled={
              isLoadingImages ||
              selectedModel.trainingStatus !== 'complete' ||
              tokens === null ||
              tokens <= 0
            }
          >
            {isLoadingImages ? (
              <>
                <FaSpinner />
                Generating...
              </>
            ) : (
              <>
                <FaMagic />
                Generate Image
              </>
            )}
          </GenerateButton>
        </Card>
      </PromptSection>

      <ImagePreviewSection>
        {isLoadingImages && (
          <LoadingOverlay>
            <SpinnerIcon />
            <StatusBadge>
              {fluxStatus.status_description}
            </StatusBadge>
            {countdown > 0 && (
              <Countdown>ETA: {countdown}s</Countdown>
            )}
          </LoadingOverlay>
        )}

        {!isLoadingImages && generatedImages.length > 0 ? (
          <>
            <GeneratedImage src={generatedImages[0]} alt="Generated" />
            <DownloadButton onClick={() => downloadImage(generatedImages[0], `generated_image.png`)}>
              <FaDownload />
              Download Image
            </DownloadButton>
          </>
        ) : !isLoadingImages && (
          <Placeholder>
            {selectedPlaceholder ? (
              <>
                <GeneratedImage src={selectedPlaceholder} alt="Placeholder" />
                <InstructionalOverlay>
                  <InstructionIcon>
                    <FaMagic />
                  </InstructionIcon>
                  Enter a prompt to generate your own image
                </InstructionalOverlay>
                <PhotoOpBadge>
                  <CatIcon>🐱</CatIcon>
                  PhotoOp Cat
                </PhotoOpBadge>
              </>
            ) : (
              <>
                <FaImage />
                <div>
                  Enter a prompt and click "Generate Image"<br />
                  to create your first image
                </div>
              </>
            )}
          </Placeholder>
        )}
      </ImagePreviewSection>
    </Container>
  );
};

export default ModelQueryUI;
