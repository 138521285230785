// src/components/OrderSupport.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  AlertCircle,
  HelpCircle,
  MessageCircle,
  ArrowRight,
} from 'lucide-react';

const SupportSection = styled.div`
  margin-bottom: 32px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1E293B;
  margin: 0 0 16px 0;
`;

const Description = styled.p`
  font-size: 0.875rem;
  color: #64748B;
  margin: 8px 0 24px 0;
`;

const OptionsGrid = styled.div`
  display: grid;
  gap: 16px;
  margin-top: 24px;
`;

const OptionButton = styled.button`
  display: flex;
  align-items: center;
  padding: 16px;
  background: white;
  border: 1px solid #E2E8F0;
  border-radius: 12px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #F8FAFC;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
`;

const IconWrapper = styled.div<{ variant?: 'warning' }>`
  margin-right: 16px;
  color: ${props => props.variant === 'warning' ? '#DC2626' : '#2563EB'};
`;

const OptionContent = styled.div`
  flex: 1;
`;

const OptionTitle = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  color: #1E293B;
  margin: 0;
`;

const OptionDescription = styled.p`
  font-size: 0.875rem;
  color: #64748B;
  margin: 4px 0 0 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  color: #1E293B;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  font-size: 0.875rem;
  color: #1E293B;
  background: white;

  &:focus {
    outline: none;
    border-color: #2563EB;
    box-shadow: 0 0 0 1px #2563EB;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  font-size: 0.875rem;
  color: #1E293B;
  resize: vertical;
  min-height: 120px;

  &:focus {
    outline: none;
    border-color: #2563EB;
    box-shadow: 0 0 0 1px #2563EB;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
`;

const Button = styled.button<{ variant?: 'primary' | 'danger' }>`
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  ${props => props.variant === 'danger' && `
    background: #DC2626;
    color: white;
    border: none;

    &:hover {
      background: #B91C1C;
    }

    &:disabled {
      background: #FCA5A5;
      cursor: not-allowed;
    }
  `}

  ${props => props.variant === 'primary' && `
    background: #2563EB;
    color: white;
    border: none;

    &:hover {
      background: #1D4ED8;
    }

    &:disabled {
      background: #93C5FD;
      cursor: not-allowed;
    }
  `}

  ${props => !props.variant && `
    background: white;
    color: #1E293B;
    border: 1px solid #E2E8F0;

    &:hover {
      background: #F8FAFC;
    }
  `}
`;

const SuccessMessage = styled.div`
  text-align: center;
  padding: 48px 24px;
`;

const SuccessTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: #1E293B;
  margin: 0 0 8px 0;
`;

const SuccessDescription = styled.p`
  font-size: 0.875rem;
  color: #64748B;
  margin: 0 0 24px 0;
`;

const ErrorMessage = styled.div`
  background-color: #FEF2F2;
  border: 1px solid #FEE2E2;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  color: #DC2626;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FAQItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FAQItem = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 12px;
  padding: 16px;
  background: white;
`;

const FAQQuestion = styled.h4`
  font-size: 1rem;
  font-weight: 500;
  color: #1E293B;
  margin: 0 0 8px 0;
`;

const FAQAnswer = styled.p`
  font-size: 0.875rem;
  color: #64748B;
  margin: 0;
  line-height: 1.5;
`;

interface OrderSupportProps {
  orderId: string;
}

const OrderSupport: React.FC<OrderSupportProps> = ({ orderId }) => {
  const [step, setStep] = useState('initial');
  const [reason, setReason] = useState('');
  const [description, setDescription] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [emailSuccess, setEmailSuccess] = useState(false);

  const handleSubmitRefund = async () => {
    if (!window.confirm(
      'Are you sure you want to request a refund? This action cannot be undone.'
    )) return;

    setIsProcessing(true);
    try {
      const functions = getFunctions();
      const processRefund = httpsCallable(functions, 'process_refund');
      await processRefund({
        orderId,
        reason,
        description
      });
      setSuccess(true);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  if (success) {
    return (
      <SuccessMessage>
        <SuccessTitle>Refund Request Submitted</SuccessTitle>
        <SuccessDescription>
          We've received your refund request. You should see the refund in your account within 5-7 business days.
        </SuccessDescription>
        <Button onClick={() => window.location.href = '/orders'}>
          Return to Orders
        </Button>
      </SuccessMessage>
    );
  }

  return (
    <>
      {step === 'initial' && (
        <SupportSection>
          <SectionTitle>How can we help?</SectionTitle>
          <OptionsGrid>
            <OptionButton onClick={() => setStep('contact')}>
              <IconWrapper>
                <MessageCircle size={24} />
              </IconWrapper>
              <OptionContent>
                <OptionTitle>Contact Support</OptionTitle>
                <OptionDescription>Questions about your order? We're here to help</OptionDescription>
              </OptionContent>
              <ArrowRight size={20} color="#94A3B8" />
            </OptionButton>

            <OptionButton onClick={() => setStep('faq')}>
              <IconWrapper>
                <HelpCircle size={24} />
              </IconWrapper>
              <OptionContent>
                <OptionTitle>View FAQ</OptionTitle>
                <OptionDescription>Find quick answers to common questions</OptionDescription>
              </OptionContent>
              <ArrowRight size={20} color="#94A3B8" />
            </OptionButton>

            {/* <OptionButton onClick={() => setStep('refund')}>
              <IconWrapper variant="warning">
                <AlertCircle size={24} />
              </IconWrapper>
              <OptionContent>
                <OptionTitle>Request Refund</OptionTitle>
                <OptionDescription>Not satisfied? Let us know why</OptionDescription>
              </OptionContent>
              <ArrowRight size={20} color="#94A3B8" />
            </OptionButton> */}
          </OptionsGrid>
        </SupportSection>
      )}

      {step === 'refund' && (
        <Form onSubmit={(e) => e.preventDefault()}>
          <SectionTitle>Request a Refund</SectionTitle>
          <Description>
            We're sorry you're not satisfied. Please help us understand why.
          </Description>

          <FormGroup>
            <Label>Reason for refund</Label>
            <Select
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              required
            >
              <option value="">Select a reason</option>
              <option value="quality">Not satisfied with image quality</option>
              <option value="different">Results look different than expected</option>
              <option value="technical">Technical issues</option>
              <option value="other">Other</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>Please provide more details</Label>
            <TextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              placeholder="Help us understand your experience..."
            />
          </FormGroup>

          {error && (
            <ErrorMessage>
              <AlertCircle size={16} />
              {error}
            </ErrorMessage>
          )}

          <ButtonGroup>
            <Button onClick={() => setStep('initial')}>
              Back
            </Button>

            <Button
              variant="danger"
              onClick={handleSubmitRefund}
              disabled={!reason || !description || isProcessing}
            >
              {isProcessing ? 'Processing...' : 'Submit Refund Request'}
            </Button>
          </ButtonGroup>
        </Form>
      )}

      {step === 'contact' && !emailSuccess && (
        <Form onSubmit={(e) => e.preventDefault()}>
          <SectionTitle>Contact Support</SectionTitle>
          <Description>
            Our support team is here to help you with any questions about your order.
          </Description>

          <FormGroup>
            <Label>Your Message</Label>
            <TextArea
              value={emailMessage}
              onChange={(e) => setEmailMessage(e.target.value)}
              required
              placeholder="Please describe your issue or question..."
            />
          </FormGroup>

          {error && (
            <ErrorMessage>
              <AlertCircle size={16} />
              {error}
            </ErrorMessage>
          )}

          <ButtonGroup>
            <Button onClick={() => setStep('initial')}>
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (!emailMessage.trim()) return;

                try {
                  const mailtoLink = `mailto:owenkamilkhoury@gmail.com?subject=${encodeURIComponent('photoop support - order email')}&body=${encodeURIComponent(`Order ID: ${orderId}\n\n${emailMessage}`)}`;
                  window.location.href = mailtoLink;
                  setEmailSuccess(true);
                } catch (err: any) {
                  setError('Failed to open email client. Please try again.');
                }
              }}
              disabled={!emailMessage.trim()}
            >
              Send Message
            </Button>
          </ButtonGroup>
        </Form>
      )}

      {step === 'contact' && emailSuccess && (
        <SuccessMessage>
          <SuccessTitle>Message Sent Successfully</SuccessTitle>
          <SuccessDescription>
            Thank you for contacting us. We'll reply to your message shortly.
          </SuccessDescription>
          <Button onClick={() => window.location.href = '/orders'}>
            Return to Orders
          </Button>
        </SuccessMessage>
      )}

      {step === 'faq' && (
        <SupportSection>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
          <Description>
            Find answers to common questions about our service.
          </Description>
          <FAQItems>
            <FAQItem>
              <FAQQuestion>Why is my order not available yet?</FAQQuestion>
              <FAQAnswer>
                Orders typically take around 3 hours to process completely. However, sometimes cloud errors can cause orders to terminate early, requiring us to re-trigger them. If your order has been processing for more than 24 hours, please reach out to our support team for assistance and a full refund.
              </FAQAnswer>
            </FAQItem>

            <FAQItem>
              <FAQQuestion>Why don't some of these photos look like me?</FAQQuestion>
              <FAQAnswer>
                While our AI technology is advanced, it can sometimes struggle with maintaining perfect consistency across all generated images. Factors like lighting, angles, and specific facial features can be challenging for the AI to reproduce perfectly. If you're not satisfied with the likeness in your photos, please contact our support team for a full refund.
              </FAQAnswer>
            </FAQItem>

            <FAQItem>
              <FAQQuestion>Can I use these photos for commercial purposes?</FAQQuestion>
              <FAQAnswer>
                Yes! The photos you receive are yours to use for both personal and commercial purposes. However, please note that while you own the rights to your generated images, our AI technology itself and its base models remain proprietary. For large-scale commercial projects, we recommend testing with a smaller order first to ensure the style meets your needs.
              </FAQAnswer>
            </FAQItem>
          </FAQItems>
          <Button onClick={() => setStep('initial')}>
            Back
          </Button>
        </SupportSection>
      )}
    </>
  );
};

export default OrderSupport;