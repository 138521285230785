// src/components/LandingPage.tsx

import React, { useEffect } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';
import Navbar from './Navbar';
import { FaBolt, FaBullhorn, FaRobot, FaBrain, FaGoogle, FaApple, FaMicrosoft, FaAmazon } from 'react-icons/fa';
import TestimonialsSection from './TestimonialsSection';
import Hero from './Hero';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebaseConfig';
import ImagePreloader from './ImagePreloader';

// Define a color palette for consistency
const colors = {
  primary: '#3b82f6',     // Blue-500
  primaryDark: '#2563eb', // Blue-600
  scanlineColor: 'rgba(255, 255, 255, 0.05)', // White with low opacity
  buttonText: '#ffffff',  // White text for buttons
  buttonTextHover: '#1f2937', // Dark text on hover
};

// Global Styles - single gradient background for the entire page
const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
    background: linear-gradient(135deg, #f0f9ff 0%, #ffffff 100%);
  }
`;

// Design tokens for consistency
const tokens = {
  colors: {
    primary: {
      start: '#4f46e5',
      end: '#3b82f6',
      hover: '#4338ca'
    },
    accent: {
      base: '#fef3c7',
      text: '#92400e',
      hover: '#fde68a'
    },
    text: {
      light: '#ffffff',
      dark: '#1f2937'
    }
  },
  shadows: {
    sm: '0 1px 2px rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px rgba(0, 0, 0, 0.1)',
    lg: '0 8px 16px rgba(0, 0, 0, 0.1)',
    inner: 'inset 0 2px 4px rgba(0, 0, 0, 0.05)'
  }
};

const shimmer = keyframes`
  0% { transform: translateX(-150%); }
  100% { transform: translateX(150%); }
`;

const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-2px); }
`;

const FuturisticBanner = styled.div`
  background: linear-gradient(
    135deg, 
    ${tokens.colors.primary.start} 0%, 
    ${tokens.colors.primary.end} 100%
  );
  padding: 14px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: ${tokens.shadows.lg},
              ${tokens.shadows.inner};
  
  // Add subtle texture
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.05) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0.05) 75%,
      transparent 75%
    );
    background-size: 8px 8px;
    opacity: 0.4;
  }

  // Add shimmer effect
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    animation: ${shimmer} 4s infinite;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const BannerText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: ${tokens.colors.text.light};
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  z-index: 1;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.2px;

  svg {
    font-size: 1.25rem;
    color: ${tokens.colors.accent.base};
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    animation: ${float} 2s ease-in-out infinite;
  }
`;

const HighlightText = styled.span`
  color: ${tokens.colors.accent.base};
  font-weight: 700;
`;

const BannerButton = styled(Link)`
  background: ${tokens.colors.text.light};
  color: ${tokens.colors.primary.hover};
  padding: 8px 20px;
  font-size: 0.9rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 30px;
  transition: all 0.2s ease;
  position: relative;
  z-index: 1;
  border: 2px solid transparent;
  
  // Add subtle inner shadow
  box-shadow: ${tokens.shadows.sm},
              ${tokens.shadows.inner};

  &:hover {
    background: ${tokens.colors.accent.base};
    color: ${tokens.colors.text.dark};
    transform: translateY(-1px);
    box-shadow: ${tokens.shadows.md};
  }

  &:active {
    transform: translateY(0);
    box-shadow: ${tokens.shadows.sm};
  }

  &:focus {
    outline: none;
    border-color: ${tokens.colors.accent.base};
    box-shadow: 0 0 0 3px rgba(254, 243, 199, 0.3);
  }
`;

// Simple fade-in
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(16px);
  } 
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Fade-in and slide up
const fadeInUp = keyframes`
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Typewriter effect (for one or two lines)
const typing = keyframes`
  0% {
    max-width: 0;
  }
  100% {
    max-width: 100%;
  }
`;

// Slide in from left (for AI workflow heading)
const slideInLeft = keyframes`
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Landing Container
const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

// AI Section (Discover Our AI Workflow)
const AISection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 40px;
  animation: ${fadeIn} 1s ease-in;
  @media (max-width: 768px) {
    padding: 40px 16px;
  }
`;

const AISectionTitle = styled.h2`
  font-size: 2rem;
  color: #111;
  margin-bottom: 20px;
  text-shadow: 0 0 5px rgba(0,0,0,0.1);
  animation: ${slideInLeft} 1s ease forwards;
`;

const AISectionSubtext = styled.p`
  font-size: 1.1rem;
  color: #495057;
  margin-bottom: 40px;
  line-height: 1.6;
  text-align: center;
  max-width: 700px;
  animation: ${fadeInUp} 0.8s ease forwards;

  @media (max-width: 480px) {
    font-size: 1rem;
    margin-bottom: 24px;
  }
`;

const FeatureCards = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  animation: ${fadeInUp} 0.8s ease forwards;
`;

const FeatureCard = styled.div`
  flex: 1 1 250px;
  max-width: 300px;
  background: #ffffffcc;
  backdrop-filter: blur(8px);
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.1);
  padding: 24px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0,0,0,0.15);
  }
`;

const CardIcon = styled.div`
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 16px;
`;

const CardTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #111;
  font-weight: 700;
`;

const CardText = styled.p`
  font-size: 1rem;
  color: #495057;
`;

// New "Trusted" Section
const TrustedSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  text-align: center;
  animation: ${fadeIn} 1s ease-in;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const TrustedTitle = styled.h2`
  font-size: 1.8rem;
  color: #111;
  margin-bottom: 24px;
  text-shadow: 0 0 3px rgba(0,0,0,0.1);
  animation: ${fadeInUp} 0.8s ease forwards;
`;

const LogoRow = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  animation: ${fadeInUp} 0.8s ease forwards;

  @media (max-width: 480px) {
    gap: 20px;
  }
`;

const PlaceholderLogo = styled.div`
  font-size: 3rem;
  color: #6c757d;
  transition: color 0.2s ease;
  &:hover {
    color: #007bff;
  }
`;

// FAQ Section
const FAQSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 40px;
  animation: ${fadeIn} 1s ease-in;

  @media (max-width: 768px) {
    padding: 40px 16px;
  }
`;

const FAQTitle = styled.h2`
  font-size: 2rem;
  color: #111;
  margin-bottom: 40px;
  text-align: center;
  text-shadow: 0 0 4px rgba(0,0,0,0.1);
  animation: ${fadeInUp} 0.8s ease forwards;
`;

const FAQList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 700px;
  width: 100%;
`;

const FAQItem = styled.div`
  background-color: #ffffffcc;
  backdrop-filter: blur(8px);
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.08);
  animation: ${fadeInUp} 0.8s ease forwards;
`;

const Question = styled.h4`
  font-size: 1.2rem;
  color: #007bff;
  margin-bottom: 10px;
  font-weight: 700;
`;

const Answer = styled.p`
  font-size: 1rem;
  color: #495057;
  line-height: 1.6;
`;

// Footer
const Footer = styled.footer`
  padding: 16px 48px;
  background: #f7f7f7cc;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: space-between;
  z-index: 2;

  @media (max-width: 768px) {
    padding: 12px 24px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
`;

const FooterText = styled.p`
  color: #777;
  font-size: 0.72rem;
`;

// Landing Page Component
const LandingPage: React.FC = () => {
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'landing_page_viewed', {
        page: 'LandingPage',
      });
    }
  }, []);

  const handleGetStartedClick = () => {
    if (analytics) {
      logEvent(analytics, 'get_started_clicked');
    }
  };

  return (
    <>
      <GlobalStyle />
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap"
          rel="stylesheet"
        />
        <title>Experience Cutting-Edge AI Portraits with PhotoOp</title>
        <meta
          name="description"
          content="Transform your photos into realistic portraits using PhotoOp's cutting-edge AI technology. Experience the future of photography today."
        />
        <meta
          name="keywords"
          content="AI portraits, AI-generated art, photo transformation, AI photo editor, AI art generator, PhotoOp, cutting-edge AI technology"
        />
        <meta property="og:title" content="Experience Cutting-Edge AI Portraits with PhotoOp" />
        <meta
          property="og:description"
          content="Transform your photos into realistic portraits using PhotoOp's cutting-edge AI technology. Experience the future of photography today."
        />
        <meta property="og:image" content="https://photoop.shop/images/ai-portrait-example.jpg" />
        <meta property="og:url" content="https://photoop.shop/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Experience Cutting-Edge AI Portraits with PhotoOp" />
        <meta
          name="twitter:description"
          content="Transform your photos into realistic portraits using PhotoOp's cutting-edge AI technology. Experience the future of photography today."
        />
        <meta name="twitter:image" content="https://photoop.shop/images/ai-portrait-example.jpg" />
      </Helmet>

      <LandingContainer>
        <ImagePreloader />
        {/* Navbar */}
        <Navbar
          navItems={[
            {
              label: 'Your Orders',
              to: '/orders',
              onClick: handleGetStartedClick,
              isButton: true,
            },
          ]}
          isSticky={true}
        />

        {/* Banner */}
        <FuturisticBanner>
          <BannerText>
            <FaBullhorn />
            New! <HighlightText>Custom Models</HighlightText> are now in beta
          </BannerText>
          <BannerButton to="/custom-models" aria-label="Try Custom Prompts">
            Try it Now
          </BannerButton>
        </FuturisticBanner>

        <Hero onGetStartedClick={handleGetStartedClick} />

        <TestimonialsSection />

        {/* AI Section (Discover Our AI Workflow) */}
        <AISection>
          <AISectionTitle>Discover Our AI Workflow</AISectionTitle>
          <AISectionSubtext>
            Leveraging the latest in deep learning and image generation research,
            our pipeline ensures stunning, hyper-realistic results for your portraits.
          </AISectionSubtext>
          <FeatureCards>
            <FeatureCard>
              <CardIcon><FaBrain /></CardIcon>
              <CardTitle>Top-Tier Models</CardTitle>
              <CardText>
                We begin with cutting-edge AI architectures from leading research institutions,
                ensuring the highest quality base for your images.
              </CardText>
            </FeatureCard>
            <FeatureCard>
              <CardIcon><FaBolt /></CardIcon>
              <CardTitle>Personalization</CardTitle>
              <CardText>
                Our specialized fine-tuning process trains models on your photos,
                capturing every detail for flawless outputs.
              </CardText>
            </FeatureCard>
            <FeatureCard>
              <CardIcon><FaRobot /></CardIcon>
              <CardTitle>Stunning Realism</CardTitle>
              <CardText>
                Through a final pass of custom scripts, we refine each image to make it pop,
                delivering photos you’ll love.
              </CardText>
            </FeatureCard>
          </FeatureCards>
        </AISection>

        {/* NEW Trusted Section */}
        <TrustedSection>
          <TrustedTitle>Trusted by Professionals at Leading Companies</TrustedTitle>
          <LogoRow>
            <PlaceholderLogo><FaGoogle /></PlaceholderLogo>
            <PlaceholderLogo><FaApple /></PlaceholderLogo>
            <PlaceholderLogo><FaMicrosoft /></PlaceholderLogo>
            <PlaceholderLogo><FaAmazon /></PlaceholderLogo>
          </LogoRow>
        </TrustedSection>

        {/* FAQ Section */}
        <FAQSection>
          <FAQTitle>Frequently Asked Questions</FAQTitle>
          <FAQList>
            <FAQItem>
              <Question>What happens to the photos I upload?</Question>
              <Answer>
                We use your photos solely to process your order. We delete all of your uploaded
                photos as soon as your order is complete. Nothing is kept or sold.
              </Answer>
            </FAQItem>
            <FAQItem>
              <Question>What image quality can I expect?</Question>
              <Answer>
                Our AI produces multiple portraits per order, from highly realistic to stylistically
                experimental. For best results, upload clear and well-lit photos.
              </Answer>
            </FAQItem>
            <FAQItem>
              <Question>Is my data secure?</Question>
              <Answer>
                Absolutely. Your data is stored behind layers of security, backed by Google.
                We take privacy seriously.
              </Answer>
            </FAQItem>
            <FAQItem>
              <Question>How does your AI technology work?</Question>
              <Answer>
                We leverage advanced deep learning models trained on enormous datasets.
                By personalizing the model to your images, we achieve hyper-realistic results.
              </Answer>
            </FAQItem>
          </FAQList>
        </FAQSection>

        {/* Optional About Us Section */}
        {/* <AboutUsSectionComponent /> */}

        {/* Footer */}
        <Footer>
          <FooterText>&copy; {new Date().getFullYear()} PhotoOp. All rights reserved.</FooterText>
        </Footer>
      </LandingContainer>
    </>
  );
};

export default LandingPage;
