import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Sparkles, ArrowRight, Star, Shield } from 'lucide-react';
import { Link } from 'react-router-dom';
import BeforeAndAfterCarousel from './BeforeAndAfterCarousel';
import {
  collection,
  getDocs,
  Firestore, // Import Firestore for typing
} from 'firebase/firestore';
import { db } from './firebaseConfig'; // Adjust if needed (some projects don't destructure `db` directly here)

// ====== TYPES ======

interface ConvertedUser {
  userId: string;
  firstFreeOrder: Date;
  firstPaidOrder: Date;
  daysBetweenOrders: number;
}

interface FreeToPaidResults {
  totalFreeUsers: number;
  totalPaidUsers: number;
  convertedUsers: ConvertedUser[];
  conversionRate: string;
}

// ====== UTILITY FUNCTION ======
const getFreeToPaidUsers = async (db: Firestore): Promise<FreeToPaidResults> => {
  // Get all free orders
  const freeOrdersRef = collection(db, 'freeOrders');
  const freeOrdersSnapshot = await getDocs(freeOrdersRef);

  // Get all paid orders
  const ordersRef = collection(db, 'orders');
  const ordersSnapshot = await getDocs(ordersRef);

  // Create maps to store user's first free and paid order dates
  const userFirstFreeOrder = new Map<string, Date>();
  const userFirstPaidOrder = new Map<string, Date>();

  // Process free orders
  freeOrdersSnapshot.forEach(doc => {
    const data = doc.data();
    const userId = data.userId as string;
    const timestamp = new Date(data.lastFreeOrderTimestamp);

    if (!userFirstFreeOrder.has(userId) || timestamp < userFirstFreeOrder.get(userId)!) {
      userFirstFreeOrder.set(userId, timestamp);
    }
  });

  // Process paid orders
  ordersSnapshot.forEach(doc => {
    const data = doc.data();
    const userId = data.userId as string;
    const timestamp = new Date(data.timestamp);

    if (!userFirstPaidOrder.has(userId) || timestamp < userFirstPaidOrder.get(userId)!) {
      userFirstPaidOrder.set(userId, timestamp);
    }
  });

  // Find users who converted from free to paid
  const convertedUsers: ConvertedUser[] = [];
  userFirstFreeOrder.forEach((freeOrderDate, userId) => {
    const paidOrderDate = userFirstPaidOrder.get(userId);

    if (paidOrderDate && paidOrderDate > freeOrderDate) {
      convertedUsers.push({
        userId,
        firstFreeOrder: freeOrderDate,
        firstPaidOrder: paidOrderDate,
        daysBetweenOrders: Math.floor((paidOrderDate.getTime() - freeOrderDate.getTime()) / (1000 * 60 * 60 * 24)),
      });
    }
  });

  return {
    totalFreeUsers: userFirstFreeOrder.size,
    totalPaidUsers: userFirstPaidOrder.size,
    convertedUsers,
    conversionRate: (convertedUsers.length / userFirstFreeOrder.size * 100).toFixed(2) + '%',
  };
};

// ====== ANIMATIONS ======

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  70% {
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const typeText = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const shimmer = keyframes`
 0% {
   background-position: 200% 0;
 }
 100% {
   background-position: 0% 0;
 }
`;

const float = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
`;

// ====== STYLED COMPONENTS ======

const GuaranteeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  background: linear-gradient(90deg, #F3F4F6 0%, #E5E7EB 100%);
  border-radius: 12px;
  padding: 12px 16px;
  margin-bottom: 32px;
  transition: all 0.2s ease;
  cursor: pointer;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 16px;
  }
`;

const GuaranteeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #34D399 0%, #10B981 100%);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

const GuaranteeContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const GuaranteeTitle = styled.span`
  color: #111827;
  font-weight: 600;
  font-size: 16px;
`;

const GuaranteeText = styled.span`
  color: #6B7280;
  font-size: 14px;
`;

const Container = styled.div`
  min-height: auto;
  background: linear-gradient(180deg, #f8faff 0%, #ffffff 100%);
  overflow-x: hidden;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 24px;

  &::before {
    content: '';
    position: absolute;
    top: -100px;
    right: -100px;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(99,102,241,0.15) 0%, rgba(99,102,241,0) 70%);
    border-radius: 50%;
    filter: blur(40px);
  }
  
  @media (min-width: 1025px) {
    min-height: auto;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 48px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
  
  @media (min-width: 1025px) {
    padding: 24px 40px;
    display: grid;
    grid-template-columns: 6fr 5fr;
    gap: 60px;
    align-items: center;
  }
`;

const LeftColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  animation: ${fadeInUp} 1s ease forwards;
  order: 1;
  
  @media (min-width: 1025px) {
    align-items: flex-start;
    text-align: left;
    max-width: 640px;
  }
`;

const RightColumn = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  animation: ${fadeInUp} 1s ease forwards 0.2s;
  order: 2;
  
  @media (min-width: 1025px) {
    order: 2;
    max-width: none;
  }
`;

const PromoBadge = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: linear-gradient(90deg, #EEF2FF 0%, #E0E7FF 100%);
  border-radius: 50px;
  padding: 8px 16px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  animation: ${slideIn} 0.8s ease forwards;
`;

const BadgeText = styled.span`
  color: #4338CA;
  font-size: 14px;
  font-weight: 600;
`;

const EnhancedPromoBadge = styled(PromoBadge)`
  background: linear-gradient(90deg, #EEF2FF 0%, #E0E7FF 100%);
  padding: 8px 20px;
`;

const BadgeContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const BadgeSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BadgeDivider = styled.div`
  width: 1px;
  height: 16px;
  background: #4338CA;
  opacity: 0.2;
`;

const HeadingContainer = styled.h1`
  font-size: clamp(28px, 5vw, 48px);
  font-weight: 800;
  color: #111827;
  line-height: 1.2;
  margin-bottom: 24px;
  height: auto;
  min-height: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  @media (min-width: 1025px) {
    height: auto;
    min-height: 160px;
    gap: 0;
  }
`;

const AnimatedTextLine = styled.div`
  position: relative;
  width: 100%;
`;

const AnimatedText = styled.div<{ delay: number }>`
  display: inline-block;
  overflow: hidden;
  white-space: normal;
  animation: ${typeText} 1s steps(40, end) forwards;
  animation-delay: ${props => props.delay}s;
  width: 0;
  
  @media (min-width: 1025px) {
    white-space: nowrap;
  }
`;

const Highlight = styled.span`
 display: inline-block;
 background: linear-gradient(
   90deg,
   #4F46E5 0%,
   #7C3AED 50%,
   #EC4899 100%
 );
 background-size: 200% 100%;
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 animation: 
   ${shimmer} 8s linear infinite,
   ${float} 4s ease-in-out infinite;
 position: relative;
 padding: 0 4px;
 font-size: clamp(28px, 5vw, 48px);
 font-weight: 800;
 
//  &::before {
//    content: attr(data-text);
//    position: absolute;
//    left: 0;
//    top: 0;
//    z-index: -1;
//    -webkit-text-fill-color: transparent;
//    background: linear-gradient(
//      90deg, 
//      #4F46E5 0%,
//      #7C3AED 50%,
//      #EC4899 100%
//    );
//    background-size: 200% 100%;
//    -webkit-background-clip: text;
//    animation: ${shimmer} 8s linear infinite;
//    opacity: 0.5;
//    filter: blur(8px);
//  }

 transition: all 0.3s ease;
 &:hover {
   transform: scale(1.02);
 }
`;

const Description = styled.p`
  font-size: clamp(16px, 4vw, 18px);
  color: #4B5563;
  line-height: 1.6;
  margin-bottom: 24px;
  max-width: 540px;
`;

const SocialProofContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  animation: ${fadeInUp} 1s ease forwards 0.4s;
  flex-wrap: wrap;
  justify-content: center;
  
  @media (min-width: 1025px) {
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
`;

const StarBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #10B981 0%, #059669 100%);
  padding: 6px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(16, 185, 129, 0.1);
  transition: transform 0.2s ease;
  margin: 0 2px;
  width: 24px;
  height: 24px;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(16, 185, 129, 0.15);
  }
`;

const StarContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
`;

const PartialStarIcon = styled(Star)`
  fill: white;
  color: white;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
  clip-path: polygon(0 0, 80% 0, 80% 100%, 0 100%);
`;

const StarIcon = styled(Star)`
  fill: white;
  color: white;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
`;

const RatingText = styled.span`
  font-size: clamp(14px, 4vw, 16px);
  font-weight: 600;
  color: #111827;
  margin-right: 8px;
`;

const ReviewCount = styled.span`
  font-size: clamp(14px, 4vw, 16px);
  color: #6B7280;
  &::before {
    content: '•';
    margin: 0 8px;
  }
`;

const CTAButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  background: linear-gradient(90deg, #4F46E5 0%, #7C3AED 50%, #EC4899 100%);
  color: white;
  font-weight: 600;
  font-size: clamp(16px, 4vw, 18px);
  padding: clamp(12px, 3vw, 16px) clamp(24px, 5vw, 32px);
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(59, 130, 246, 0.25);
  margin-bottom: 48px;

  svg {
    transition: transform 0.3s ease;
  }
  
  &:hover svg {
    transform: translateX(4px) rotate(-5deg);
  }

  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

const CarouselWrapper = styled.div`
  background: white;
  border-radius: 24px;
  padding: clamp(16px, 4vw, 24px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 24px;
    padding: 2px;
    background: linear-gradient(90deg, #3B82F6 0%, #2563EB 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
  flex-wrap: wrap;
  justify-content: center;
  
  @media (min-width: 1025px) {
    justify-content: flex-start;
  }
`;

// ====== PROPS ======
interface HeroProps {
  onGetStartedClick?: () => void;
}

const Hero: React.FC<HeroProps> = ({ onGetStartedClick }) => {
  return (
    <Container>
      <ContentWrapper>
        <LeftColumn>
          <EnhancedPromoBadge>
            <BadgeContent>
              <BadgeSection>
                <Sparkles size={16} color="#4338CA" />
                <BadgeText>Winter Sale - 40% Off</BadgeText>
              </BadgeSection>

              <BadgeDivider />

              <BadgeSection>
                <Shield size={16} color="#4338CA" />
                <BadgeText>30-Day Guarantee</BadgeText>
              </BadgeSection>
            </BadgeContent>
          </EnhancedPromoBadge>
          <HeadingContainer>
            <AnimatedTextLine>
              <AnimatedText delay={0}>Transform Your Photos into</AnimatedText>
            </AnimatedTextLine>
            <AnimatedTextLine>
              <AnimatedText delay={1}>
                <Highlight data-text="Beautiful Portraits">Beautiful Portraits</Highlight>
              </AnimatedText>
            </AnimatedTextLine>
            <AnimatedTextLine>
              <AnimatedText delay={2}>with Cutting-Edge AI</AnimatedText>
            </AnimatedTextLine>
          </HeadingContainer>

          <Description>
            Create striking professional portraits for LinkedIn and business using AI - your photos, expertly enhanced.
          </Description>

          <SocialProofContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StarContainer>
                <StarBox>
                  <StarIcon size={12} />
                </StarBox>
                <StarBox>
                  <StarIcon size={12} />
                </StarBox>
                <StarBox>
                  <StarIcon size={12} />
                </StarBox>
                <StarBox>
                  <StarIcon size={12} />
                </StarBox>
                <StarBox>
                  <PartialStarIcon size={16} />
                </StarBox>
              </StarContainer>
              <RatingText>4.9</RatingText>
            </div>
            <ReviewCount>263 reviews</ReviewCount>
          </SocialProofContainer>

          <CTAButton to="/select-template" onClick={onGetStartedClick}>
            Get your portraits!
            <ArrowRight size={20} />
          </CTAButton>
        </LeftColumn>

        <RightColumn>
          <CarouselWrapper>
            <BeforeAndAfterCarousel />
          </CarouselWrapper>
        </RightColumn>
      </ContentWrapper>
    </Container>
  );
};

export default Hero;
