import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, Sparkles } from 'lucide-react';
import { FaCog } from 'react-icons/fa';

// Image imports
import owenAfter from './assets/beforeAfter/owenAfter.png';
import owenBefore from './assets/beforeAfter/owenBefore.png';
import omarAfter from './assets/linkedin/business_casual.png';
import omarBefore from './assets/beforeAfter/omarBefore.png';
import justinAfter from './assets/beforeAfter/justinAfter.png';
import justinBefore from './assets/beforeAfter/justinBefore.png';
import tinaAfter from './assets/hairstyle/buzzcut.png';
import tinaBefore from './assets/beforeAfter/tinaBefore.png';

const beforeAfterPairs = [
  {
    before: tinaBefore,
    after: tinaAfter,
    caption: "Professional Portraits",
    quote: "What do I look like with a buzzcut?"
  },
  {
    before: owenBefore,
    after: owenAfter,
    caption: "Classic Photography",
    quote: "How do I look in a vintage polaroid?"
  },
  {
    before: omarBefore,
    after: omarAfter,
    caption: "Social Media Ready",
    quote: "Can I get a new LinkedIn profile?"
  },
  // {
  //   before: justinBefore,
  //   after: justinAfter,
  //   caption: "LinkedIn Professional",
  //   quote: "Can I get a new LinkedIn profile?"
  // },
];

const tokens = {
  colors: {
    primary: '#4F46E5',
    primaryLight: '#818CF8',
    primaryDark: '#3730A3',
    white: '#ffffff',
    overlay: 'rgba(0, 0, 0, 0.75)',
    gradient: 'linear-gradient(135deg, #4F46E5, #818CF8)',
  },
  shadows: {
    sm: '0 2px 8px rgba(79, 70, 229, 0.1)',
    md: '0 4px 12px rgba(79, 70, 229, 0.15)',
    lg: '0 8px 24px rgba(79, 70, 229, 0.2)',
    glow: '0 0 20px rgba(79, 70, 229, 0.4)',
  }
};

const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const fadeScale = keyframes`
  0% { opacity: 0; transform: scale(0.95); }
  100% { opacity: 1; transform: scale(1); }
`;

const Container = styled.div`
  padding: 16px;
  max-width: 1800px;
  margin: 0 auto;
  cursor: pointer;
  
  &:hover {
    .image-wrapper {
      transform: translateY(-8px);
      box-shadow: ${tokens.shadows.glow};
    }
  }
`;

const QuoteBox = styled.div<{ isVisible: boolean }>`
  display: inline-flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 8px;
  border-radius: 12px;
  background: rgba(16, 185, 129, 0.1);
  padding: 8px 16px;
  box-shadow: 0 2px 8px rgba(16, 185, 129, 0.1);
  border: 1px solid rgba(16, 185, 129, 0.2);
  max-width: fit-content;
`;

const QuoteText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: white;
  margin: 0;
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const SpinningCog = styled(FaCog)`
  animation: ${spin} 2s linear infinite;
  color: #10b981;
  font-size: 12px;
`;

const Quote = styled.p`
  font-size: 0.875rem;
  color: #10b981;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
`;

const Gallery = styled.div<{ isVisible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  opacity: ${props => props.isVisible ? 1 : 0};
  animation: ${props => props.isVisible ? css`${fadeScale} 0.6s ease forwards` : 'none'};
`;

const ImageWrapper = styled.div`
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: ${tokens.shadows.lg};
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(to bottom, transparent 60%, rgba(0,0,0,0.4));
  }
`;

const Image = styled.img`
  width: 198px;
  height: 248px;
  object-fit: cover;
  display: block;
  transition: transform 0.4s ease;
  
  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 144px;
    height: 180px;
  }
`;

const ArrowContainer = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${tokens.colors.gradient};
  border-radius: 50%;
  box-shadow: ${tokens.shadows.md};
  // animation: ${float} 6s ease-in-out infinite;

  svg {
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0.1));
  }
`;

const Label = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: ${tokens.colors.gradient};
  color: ${tokens.colors.white};
  padding: 8px 16px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  z-index: 1;
  box-shadow: ${tokens.shadows.sm};
`;

const QuoteContainer = styled.div<{ isVisible: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  padding: 12px 16px;
  border-radius: 9999px;
  background: linear-gradient(90deg, #4F46E5 0%, #7C3AED 100%);
  transform: translateY(${props => props.isVisible ? '0' : '-16px'});
  opacity: ${props => props.isVisible ? '1' : '0'};
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
`;

const BeforeAndAfterCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentIndex(prev => (prev + 1) % beforeAfterPairs.length);
        setIsVisible(true);
      }, 600);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const currentPair = beforeAfterPairs[currentIndex];

  return (
    <Container onClick={() => navigate('/select-template')}>
      <QuoteContainer isVisible={isVisible}>
        <IconWrapper>
          {/* <Sparkles size={16} color="white" /> */}
          <SpinningCog size={14} />
        </IconWrapper>
        <QuoteText>{currentPair.quote}</QuoteText>
      </QuoteContainer>
      <Gallery isVisible={isVisible}>
        <ImageWrapper className="image-wrapper">
          <Image src={currentPair.before} alt="Before" />
        </ImageWrapper>
        <ArrowContainer>
          <ArrowRight size={32} color={tokens.colors.white} />
        </ArrowContainer>
        <ImageWrapper className="image-wrapper">
          <Image src={currentPair.after} alt="After" />
          <Label>After</Label>
        </ImageWrapper>
      </Gallery>
    </Container>
  );
};

export default BeforeAndAfterCarousel;