import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Upload, X, Check, Plus, AlertTriangle } from 'lucide-react';

const colors = {
  primary: '#2563EB',
  secondary: '#3B82F6',
  background: '#F9FAFB',
  surface: '#FFFFFF',
  error: '#DC2626',
  onPrimary: '#FFFFFF',
  onBackground: '#111827',
};

const Container = styled.div`
  width: 100%;
  background: ${colors.surface};
  border-radius: 16px;
`;

const Header = styled.div`
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 1.7rem;
  font-weight: 700;
  color: #1E293B;
  margin: 0 0 8px 0;
  letter-spacing: -0.02em;
`;

const Subtitle = styled.p`
  font-size: 0.95rem;
  color: #64748B;
  margin: 0;
  line-height: 1.6;
`;

const Dropzone = styled.div<{ isDragActive: boolean; isVerifying: boolean }>`
  border: 2px dashed ${({ isDragActive }) => isDragActive ? colors.primary : '#E2E8F0'};
  border-radius: 12px;
  padding: 32px;
  text-align: center;
  background: ${({ isDragActive }) => isDragActive ?
    'linear-gradient(135deg, #EFF6FF 0%, #F8FAFC 100%)' :
    'linear-gradient(135deg, #F8FAFC 0%, #FFFFFF 100%)'};
  transition: all 0.3s ease;
  cursor: ${({ isVerifying }) => isVerifying ? 'wait' : 'pointer'};
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
  opacity: ${({ isVerifying }) => isVerifying ? 0.7 : 1};
  &:hover {
    border-color: ${colors.primary};
    background: linear-gradient(135deg, #EFF6FF 0%, #F8FAFC 100%);
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent, rgba(59, 130, 246, 0.03), transparent);
    background-size: 200% 200%;
    animation: shine 3s linear infinite;
  }
  @keyframes shine {
    0% { background-position: -200% 0; }
    100% { background-position: 200% 0; }
  }
`;

const DropzoneContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;
  z-index: 1;
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(37, 99, 235, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.primary};
  margin-bottom: 8px;
`;

const DropzoneText = styled.div`
  font-size: 0.95rem;
  color: #1E293B;
  strong {
    color: ${colors.primary};
    font-weight: 600;
  }
`;

const UploadInput = styled.input`
  display: none;
`;

const GalleryContainer = styled.div`
  margin: 24px 0;
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 16px;
  padding: 4px;
`;

const PhotoWrapper = styled.div`
  position: relative;
  aspect-ratio: 1;
  border-radius: 12px;
  overflow: hidden;
  background: #F1F5F9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-center: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease, transform 0.2s ease;
  ${PhotoWrapper}:hover & {
    opacity: 1;
  }
  &:hover {
    transform: scale(1.1);
  }
`;

const GuidelinesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`;

const GuidelineItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: #F8FAFC;
  border-radius: 10px;
  border: 1px solid #E2E8F0;
`;

const GuidelineIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${colors.primary}15;
  color: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GuidelineText = styled.span`
  font-size: 0.95rem;
  color: #1E293B;
  line-height: 1.5;
`;

const AddMoreButton = styled.button`
  padding: 12px 24px;
  background: linear-gradient(135deg, #3B82F6 0%, #2563EB 100%);
  color: white;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  font-size: 0.95rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(37, 99, 235, 0.25);
  }
`;

const ErrorMessage = styled.div`
  color: ${colors.error};
  font-size: 0.9rem;
  padding: 8px 12px;
  background: #FEF2F2;
  border: 1px solid #FEE2E2;
  border-radius: 8px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface VerificationResult {
  index: number;
  safe: boolean;
  details?: {
    adult: string;
    violence: string;
    racy: string;
    medical: string;
  };
  error?: string;
}

interface VerificationResponse {
  results: VerificationResult[];
  total_processed: number;
  all_safe: boolean;
}

interface PhotoUploadProps {
  onPhotoUpload: (newPhotos: File[]) => void;
}

const PhotoUpload: React.FC<PhotoUploadProps> = ({ onPhotoUpload }) => {
  const [photos, setPhotos] = useState<File[]>([]);
  const [isDragActive, setIsDragActive] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result?.toString().split(',')[1];
        resolve(base64 || '');
      };
      reader.onerror = reject;
    });
  };

  const handlePhotoUpload = async (files: FileList | null) => {
    if (!files || files.length === 0) return;

    try {
      setIsVerifying(true);
      setError('');

      const remainingSlots = 12 - photos.length;
      const newPhotos = Array.from(files).slice(0, remainingSlots);

      const allowedPhotos = newPhotos.filter(
        file => ['image/png', 'image/jpeg'].includes(file.type)
      );

      if (allowedPhotos.length < newPhotos.length) {
        setError('Only .png and .jpg files are allowed.');
        return;
      }

      const base64Photos = await Promise.all(
        allowedPhotos.map(fileToBase64)
      );

      const response = await fetch('https://us-central1-photoop-28a4c.cloudfunctions.net/verify_images', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ images: base64Photos })
      });

      const result = await response.json() as VerificationResponse;

      if (!result.all_safe) {
        const unsafeIndexes = result.results
          .filter((r: VerificationResult) => !r.safe)
          .map((r: VerificationResult) => r.index);

        setError('One or more images contain inappropriate content. Please review your selection.');
        return;
      }

      const updatedPhotos = [...photos, ...allowedPhotos];
      setPhotos(updatedPhotos);
      onPhotoUpload(updatedPhotos);

    } catch (err) {
      setError('Error verifying images. Please try again.');
    } finally {
      setIsVerifying(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handlePhotoUpload(event.target.files);
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  const handleRemovePhoto = (index: number) => {
    const updatedPhotos = photos.filter((_, i) => i !== index);
    setPhotos(updatedPhotos);
    onPhotoUpload(updatedPhotos);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragActive(false);
    handlePhotoUpload(event.dataTransfer.files);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragActive(false);
  };

  return (
    <Container>
      <Header>
        <Title>Upload your photos</Title>
        <Subtitle>Upload at least 5 high-quality photos to get started</Subtitle>
      </Header>
      <Dropzone
        isDragActive={isDragActive}
        isVerifying={isVerifying}
        onClick={() => !isVerifying && fileInputRef.current?.click()}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <DropzoneContent>
          <IconWrapper>
            {isVerifying ? <AlertTriangle size={24} /> : <Upload size={24} />}
          </IconWrapper>
          <DropzoneText>
            {isVerifying ? (
              'Verifying your photos...'
            ) : photos.length === 0 ? (
              <>Drag & drop your photos here or <strong>browse</strong></>
            ) : (
              <>Add more photos or drag & drop them here</>
            )}
          </DropzoneText>
        </DropzoneContent>
      </Dropzone>

      <UploadInput
        ref={fileInputRef}
        type="file"
        accept=".png,.jpg,.jpeg"
        multiple
        onChange={handleInputChange}
        disabled={isVerifying}
      />

      {error && (
        <ErrorMessage>
          <AlertTriangle size={16} />
          {error}
        </ErrorMessage>
      )}

      {photos.length === 0 ? (
        <GuidelinesList>
          <GuidelineItem>
            <GuidelineIcon><Check size={16} /></GuidelineIcon>
            <GuidelineText>Use photos with good lighting and clear face visibility</GuidelineText>
          </GuidelineItem>
          <GuidelineItem>
            <GuidelineIcon><Check size={16} /></GuidelineIcon>
            <GuidelineText>Ensure no other people are in the photos</GuidelineText>
          </GuidelineItem>
          <GuidelineItem>
            <GuidelineIcon><Check size={16} /></GuidelineIcon>
            <GuidelineText>Include a variety of angles and expressions</GuidelineText>
          </GuidelineItem>
        </GuidelinesList>
      ) : (
        <GalleryContainer>
          <Gallery>
            {photos.map((photo, index) => (
              <PhotoWrapper key={index}>
                <Photo src={URL.createObjectURL(photo)} alt={`Uploaded ${index + 1}`} />
                <RemoveButton
                  onClick={() => handleRemovePhoto(index)}
                  aria-label="Remove photo"
                  disabled={isVerifying}
                >
                  <X size={14} />
                </RemoveButton>
              </PhotoWrapper>
            ))}
          </Gallery>
          {photos.length < 12 && (
            <AddMoreButton
              onClick={() => !isVerifying && fileInputRef.current?.click()}
              disabled={isVerifying}
            >
              <Plus size={18} />
              Add More Photos
            </AddMoreButton>
          )}
        </GalleryContainer>
      )}
    </Container>
  );
};

export default PhotoUpload;