import React, { useState } from 'react';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { doc, increment, updateDoc } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FaCheckCircle, FaTimes, FaShieldAlt, FaLock } from 'react-icons/fa';

const stripePromise = loadStripe('pk_live_51Q7mlYAo8DkCAAeqKbJ2AkantXN1kH4UOBXESsBNI7leUB3cNCcYTdruYLNT1yrpJ92ZlvbQJeFK0seBqkJVMH7A00ilD1QgC0');

const theme = {
  colors: {
    background: '#ffffff',
    surface: '#f8fafc',
    primary: '#3b82f6',
    primaryDark: '#2563eb',
    success: '#10b981',
    error: '#ef4444',
    text: {
      primary: '#1e293b',
      secondary: '#64748b',
      light: '#94a3b8'
    },
    border: '#e2e8f0'
  },
  shadows: {
    sm: '0 1px 3px rgba(0, 0, 0, 0.1)',
    md: '0 4px 6px rgba(0, 0, 0, 0.1)',
    lg: '0 10px 15px rgba(0, 0, 0, 0.1)'
  }
};

const Container = styled.div`
  width: 100%;
  padding: 40px;
  background: ${theme.colors.background};
  border-radius: 16px;
  box-shadow: ${theme.shadows.lg};
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 48px;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding: 32px 24px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  color: ${theme.colors.text.secondary};
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &:hover {
    background: ${theme.colors.surface};
    color: ${theme.colors.text.primary};
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 48px;
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: 800;
  color: ${theme.colors.text.primary};
  margin-bottom: 12px;
  letter-spacing: -0.02em;
`;

const PackageGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PackageCard = styled.div<{ selected: boolean }>`
  background: ${props => props.selected ? theme.colors.surface : theme.colors.background};
  border: 2px solid ${props => props.selected ? theme.colors.primary : theme.colors.border};
  border-radius: 12px;
  padding: 24px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;

  ${props => props.selected && `
    box-shadow: 0 0 0 2px ${theme.colors.primary}20;
  `}

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${theme.shadows.md};
  }
`;

const PopularBadge = styled.div`
  position: absolute;
  top: 12px;
  right: -32px;
  background: ${theme.colors.primary};
  color: white;
  padding: 6px 40px;
  transform: rotate(45deg);
  font-size: 12px;
  font-weight: 600;
`;

const PackageHeader = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.border};
`;

const TokenAmount = styled.div`
  font-size: 36px;
  font-weight: 800;
  color: ${theme.colors.text.primary};
  margin-bottom: 4px;
`;

const Price = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${theme.colors.primary};
`;

const PriceDetail = styled.div`
  font-size: 14px;
  color: ${theme.colors.text.secondary};
  margin-top: 4px;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Feature = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: ${theme.colors.text.secondary};
  margin-bottom: 8px;

  svg {
    color: ${theme.colors.success};
  }
`;

const PaymentSection = styled.div`
  max-width: 480px;
  margin: 0 auto;
  padding: 32px;
  background: ${theme.colors.surface};
  border-radius: 12px;
  box-shadow: ${theme.shadows.sm};
`;

const PaymentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.border};
`;

const SecurityBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: ${theme.colors.text.secondary};
  padding: 8px 16px;
  background: ${theme.colors.background};
  border-radius: 8px;
  margin-bottom: 24px;
`;

const CardElementWrapper = styled.div`
  background: ${theme.colors.background};
  border: 1px solid ${theme.colors.border};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
`;

const SubmitButton = styled.button<{ processing?: boolean }>`
  width: 100%;
  padding: 14px;
  background: ${props => props.processing ? theme.colors.text.secondary : theme.colors.primary};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: ${props => props.processing ? 'not-allowed' : 'pointer'};
  transition: all 0.2s;

  &:hover:not(:disabled) {
    background: ${theme.colors.primaryDark};
  }
`;

const Message = styled.div<{ type: 'error' | 'success' }>`
  margin-top: 16px;
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  
  ${props => props.type === 'error' ? `
    background: ${theme.colors.error}10;
    color: ${theme.colors.error};
  ` : `
    background: ${theme.colors.success}10;
    color: ${theme.colors.success};
  `}
`;

const PaymentForm: React.FC<{ amount: number; tokens: number; onSuccess: () => void }> = ({
  amount,
  tokens,
  onSuccess,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [user] = useAuthState(auth);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements || !user) return;

    setIsProcessing(true);
    setError('');
    setSuccess('');

    try {
      const idToken = await user.getIdToken();
      const response = await fetch(
        'https://us-central1-photoop-28a4c.cloudfunctions.net/create_payment_intent',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({ amount: Math.round(amount * 100) }),
        }
      );

      const { clientSecret } = await response.json();
      const cardElement = elements.getElement(CardElement);
      if (!cardElement || !clientSecret) return;

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card: cardElement },
      });

      if (result.error) {
        setError(result.error.message || 'Payment failed');
      } else if (result.paymentIntent?.status === 'succeeded') {
        const userDocRef = doc(db, 'userTokens', user.email!);
        await updateDoc(userDocRef, { tokens: increment(tokens) });
        setSuccess('Payment successful! Your tokens have been added.');
        onSuccess();
      }
    } catch (err) {
      setError('An error occurred during payment');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <PaymentSection>
      <PaymentHeader>
        <FaLock size={20} color={theme.colors.primary} />
        <div>
          <h3>Secure Payment</h3>
          <p>Your transaction is protected</p>
        </div>
      </PaymentHeader>

      <SecurityBadge>
        <FaShieldAlt /> 256-bit SSL Encrypted
      </SecurityBadge>

      <form onSubmit={handleSubmit}>
        <CardElementWrapper>
          <CardElement options={{
            style: {
              base: {
                fontSize: '16px',
                color: theme.colors.text.primary,
                '::placeholder': {
                  color: theme.colors.text.light,
                },
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
              },
            },
          }} />
        </CardElementWrapper>

        <SubmitButton processing={isProcessing} disabled={isProcessing}>
          {isProcessing ? 'Processing...' : `Pay $${amount.toFixed(2)}`}
        </SubmitButton>

        {error && (
          <Message type="error">
            <FaTimes /> {error}
          </Message>
        )}
        {success && (
          <Message type="success">
            <FaCheckCircle /> {success}
          </Message>
        )}
      </form>
    </PaymentSection>
  );
};

const PurchaseTokens: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [selectedOption, setSelectedOption] = useState<number>(50);

  const packages = [
    {
      tokens: 10,
      price: 1.99,
      features: ['Ideal for testing', 'No commitment required', 'Instant delivery']
    },
    {
      tokens: 50,
      price: 5.99,
      popular: true,
      features: ['Most popular choice', 'Great value for money', '24/7 priority support']
    },
    {
      tokens: 100,
      price: 9.99,
      features: ['Best value per token', 'Unlimited generations', 'Premium support']
    }
  ];

  const selectedPackage = packages.find(pkg => pkg.tokens === selectedOption);

  return (
    <Container>
      <CloseButton onClick={onClose}>
        <FaTimes size={20} />
      </CloseButton>

      <div>
        <Header>
          <Title>Purchase Tokens</Title>
        </Header>

        <PackageGrid>
          {packages.map((pkg) => (
            <PackageCard
              key={pkg.tokens}
              selected={pkg.tokens === selectedOption}
              onClick={() => setSelectedOption(pkg.tokens)}
            >
              {pkg.popular && <PopularBadge>Most Popular</PopularBadge>}

              <PackageHeader>
                <TokenAmount>{pkg.tokens} Tokens</TokenAmount>
                <Price>${pkg.price.toFixed(2)}</Price>
                <PriceDetail>${(pkg.price / pkg.tokens).toFixed(2)} per token</PriceDetail>
              </PackageHeader>

              <FeatureList>
                {pkg.features.map((feature, index) => (
                  <Feature key={index}>
                    <FaCheckCircle size={16} />
                    {feature}
                  </Feature>
                ))}
              </FeatureList>
            </PackageCard>
          ))}
        </PackageGrid>
      </div>

      <div>
        {selectedPackage && (
          <Elements stripe={stripePromise}>
            <PaymentForm
              amount={selectedPackage.price}
              tokens={selectedPackage.tokens}
              onSuccess={onClose}
            />
          </Elements>
        )}
      </div>
    </Container>
  );
};

export default PurchaseTokens;