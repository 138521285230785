// src/components/OrderExample.tsx

import React, { useEffect, useState } from 'react';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import styled from 'styled-components';
import { storage } from './firebaseConfig';
import Navbar from './Navbar';

// Constants for consistent styling
const colors = {
    primary: '#2563eb',      // Blue-600
    secondary: '#3b82f6',    // Blue-500
    accent: '#0ea5e9',       // Sky-500
    text: {
        primary: '#1f2937',    // Gray-800
        secondary: '#4b5563',  // Gray-600
        accent: '#2563eb',     // Blue-600
    },
    background: {
        primary: '#ffffff',
        secondary: '#f9fafb',  // Gray-50
        tertiary: '#f3f4f6',   // Gray-100
    },
    border: '#e5e7eb',       // Gray-200
    error: '#dc2626',        // Red-600
};

const shadows = {
    sm: '0 1px 2px rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
};

// Container with improved layout and responsive design
const Container = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    padding: 48px 40px;
    min-height: calc(100vh - 64px); // Adjust based on navbar height
    background: ${colors.background.primary};
  
    @media (max-width: 1024px) {
      padding: 40px 32px;
    }
  
    @media (max-width: 768px) {
      padding: 32px 24px;
    }
  
    @media (max-width: 480px) {
      padding: 24px 16px;
    }
  `;

// Enhanced title with better typography and spacing
const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 800;
    color: ${colors.text.primary};
    margin-bottom: 2rem;
    letter-spacing: -0.025em;
    line-height: 1.2;
    position: relative;
    
    &::after {
      content: '';
      display: block;
      width: 60px;
      height: 4px;
      background: linear-gradient(to right, ${colors.primary}, ${colors.secondary});
      margin-top: 0.75rem;
      border-radius: 2px;
    }
  
    @media (max-width: 1024px) {
      font-size: 2.25rem;
    }
  
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  
    @media (max-width: 480px) {
      font-size: 1.75rem;
    }
  `;

// Section title with improved hierarchy and visual treatment
const SectionTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 700;
    color: ${colors.text.primary};
    margin: 2.5rem 0 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    
    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 24px;
      background: linear-gradient(to bottom, ${colors.primary}, ${colors.secondary});
      border-radius: 2px;
    }
  `;

// Dynamic text with enhanced visual treatment
const DynamicText = styled.span`
    color: ${colors.text.accent};
    font-weight: 600;
    position: relative;
    padding: 0.25rem 0.5rem;
    background: linear-gradient(120deg, ${colors.primary}15, ${colors.secondary}15);
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
  
    &:hover {
      background: linear-gradient(120deg, ${colors.primary}25, ${colors.secondary}25);
    }
  `;

// Enhanced image grid with better spacing and responsiveness
const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 24px;
    margin: 1.5rem 0 2.5rem;
    padding: 0.5rem;
  
    @media (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: 20px;
    }
  
    @media (max-width: 480px) {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      gap: 16px;
    }
  `;

// Enhanced image component with better loading and hover states
const Image = styled.img`
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 12px;
    background: ${colors.background.tertiary};
    box-shadow: ${shadows.md};
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateZ(0);
    backface-visibility: hidden;
  
    &:hover {
      transform: translateY(-4px);
      box-shadow: ${shadows.xl};
    }
  
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.02));
      border-radius: inherit;
      pointer-events: none;
    }
  `;

// Enhanced error message with better visual feedback
const ErrorMessage = styled.div`
    background: #fee2e2;
    border: 1px solid #fecaca;
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem 0;
    color: ${colors.error};
    font-size: 0.975rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
  
    &::before {
      content: '⚠️';
      font-size: 1.2em;
    }
  `;

// Enhanced loading message with animation
const LoadingMessage = styled.div`
    color: ${colors.text.secondary};
    font-size: 1rem;
    text-align: center;
    padding: 2rem;
    background: ${colors.background.secondary};
    border-radius: 8px;
    margin: 1rem 0;
    position: relative;
    overflow: hidden;
  
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 200%;
      height: 100%;
      background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.4),
        transparent
      );
      animation: shimmer 2s infinite linear;
    }
  
    @keyframes shimmer {
      0% { transform: translateX(-100%); }
      100% { transform: translateX(100%); }
    }
  `;

// Component
const OrderExample: React.FC = () => {
    const [uploadedImages, setUploadedImages] = useState<string[]>([]);
    const [creativeProfessionalImages, setCreativeProfessionalImages] = useState<string[]>([]);
    const [formalHeadshotImages, setFormalHeadshotImages] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [fetchError, setFetchError] = useState('');

    useEffect(() => {
        const fetchImages = async () => {
            const orderId = '37b5e754-5538-410e-8bae-f6ed83b08a59';

            try {
                // Fetch uploaded photos
                const uploadedRef = ref(storage, `example_order_images`);
                const uploadedList = await listAll(uploadedRef);
                const uploadedUrls = await Promise.all(
                    uploadedList.items.map((fileRef) => getDownloadURL(fileRef))
                );
                setUploadedImages(uploadedUrls);

                // Fetch generated photos
                const generatedRef = ref(storage, `user_outputs/${orderId}`);
                const generatedList = await listAll(generatedRef);
                const generatedUrls = await Promise.all(
                    generatedList.items.map((fileRef) =>
                        getDownloadURL(fileRef).then((url) => ({
                            url,
                            name: fileRef.name,
                        }))
                    )
                );

                // Categorize images
                const creativeImages = generatedUrls
                    .filter((file) => file.name.includes('linkedin-3'))
                    .map((file) => file.url);
                const formalImages = generatedUrls
                    .filter((file) => file.name.includes('linkedin-1'))
                    .map((file) => file.url);

                setCreativeProfessionalImages(creativeImages);
                setFormalHeadshotImages(formalImages);
            } catch (error) {
                console.error('Error fetching images:', error);
                setFetchError('Failed to fetch images from Firebase Storage.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchImages();
    }, []);

    return (
        <>
            <Navbar
                leftNavItems={[
                    {
                        label: 'Back',
                        to: '/select-template',
                        isButton: true
                    },
                ]}
            />
            <Container>
                <Title>Example Order</Title>
                {isLoading && <LoadingMessage>Loading images...</LoadingMessage>}
                {fetchError && <ErrorMessage>{fetchError}</ErrorMessage>}

                {!isLoading && !fetchError && (
                    <>
                        {/* Uploaded Photos Section */}
                        <SectionTitle>Uploaded Photos</SectionTitle>
                        {uploadedImages.length > 0 ? (
                            <ImageGrid>
                                {uploadedImages.map((url, index) => (
                                    <Image key={index} src={url} alt={`Uploaded Photo ${index + 1}`} />
                                ))}
                            </ImageGrid>
                        ) : (
                            <LoadingMessage>No uploaded photos found.</LoadingMessage>
                        )}


                        {/* Formal Headshot Section */}
                        <SectionTitle>Order Pack: <DynamicText>Formal Headshot</DynamicText></SectionTitle>
                        {formalHeadshotImages.length > 0 ? (
                            <ImageGrid>
                                {formalHeadshotImages.map((url, index) => (
                                    <Image key={index} src={url} alt={`Formal Headshot ${index + 1}`} />
                                ))}
                            </ImageGrid>
                        ) : (
                            <LoadingMessage>No images found for Formal Headshot.</LoadingMessage>
                        )}

                        {/* Creative Professional Section */}
                        <SectionTitle>Order Pack: <DynamicText>Creative Professional</DynamicText></SectionTitle>
                        {creativeProfessionalImages.length > 0 ? (
                            <ImageGrid>
                                {creativeProfessionalImages.map((url, index) => (
                                    <Image key={index} src={url} alt={`Creative Professional ${index + 1}`} />
                                ))}
                            </ImageGrid>
                        ) : (
                            <LoadingMessage>No images found for Creative Professional.</LoadingMessage>
                        )}
                    </>
                )}
            </Container>
        </>
    );
};

export default OrderExample;
