import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { auth } from './firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import logoImage from './assets/logo.png';
import Stepper from './Stepper';

const tokens = {
  colors: {
    primary: '#3b82f6',
    primaryDark: '#2563eb',
    hover: '#1d4ed8',
    background: '#ffffff',
    text: {
      primary: '#111827',
      secondary: '#4b5563',
      light: '#ffffff'
    },
    border: '#e5e7eb'
  },
  shadows: {
    sm: '0 1px 2px rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px rgba(0, 0, 0, 0.05)',
    lg: '0 10px 15px rgba(0, 0, 0, 0.05)'
  },
  transitions: {
    standard: 'all 0.3s ease'
  }
};

const NavbarContainer = styled.nav<{ isSticky?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px;
  background: ${tokens.colors.background};
  position: relative;
  z-index: 1000;

  ${props => props.isSticky && `
    position: sticky;
    top: 0;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(8px);
    box-shadow: ${tokens.shadows.lg};
  `}

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      ${tokens.colors.border},
      transparent
    );
  }

  @media (max-width: 768px) {
    padding: 12px 20px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 1.5rem;
  font-weight: 700;
  color: ${tokens.colors.text.primary};
  text-decoration: none;
  transition: ${tokens.transitions.standard};

  img {
    height: 32px;
    transition: ${tokens.transitions.standard};
  }

  &:hover {
    color: ${tokens.colors.primary};
    img {
      transform: scale(1.05);
    }
  }
`;

const LeftNavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const NavLinkStyled = styled(Link)`
  color: ${tokens.colors.text.secondary};
  text-decoration: none;
  font-size: 0.95rem;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 6px;
  transition: ${tokens.transitions.standard};

  &:hover {
    color: ${tokens.colors.primary};
    background: rgba(59, 130, 246, 0.05);
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 4px 8px;
  }
`;

const NavButton = styled(Link)`
  background: linear-gradient(90deg, ${tokens.colors.primary}, ${tokens.colors.primaryDark});
  color: ${tokens.colors.text.light};
  font-size: 0.95rem;
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: ${tokens.transitions.standard};
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: ${tokens.transitions.standard};
  }

  &:hover {
    transform: translateY(-1px);
    box-shadow: ${tokens.shadows.md};

    &::before {
      left: 100%;
    }
  }

  &:active {
    transform: translateY(0);
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 6px 16px;
  }
`;

const SignOutButton = styled.button`
  background: transparent;
  color: ${tokens.colors.text.secondary};
  font-size: 0.95rem;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 50px;
  border: 1px solid ${tokens.colors.border};
  cursor: pointer;
  transition: ${tokens.transitions.standard};

  &:hover {
    color: ${tokens.colors.primary};
    border-color: ${tokens.colors.primary};
    background: rgba(59, 130, 246, 0.05);
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 6px 16px;
  }
`;

const ProductDropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  color: ${tokens.colors.text.secondary};
  background: transparent;
  border: none;
  font-size: 0.95rem;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 6px;
  transition: ${tokens.transitions.standard};
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  &:hover {
    color: ${tokens.colors.primary};
    background: rgba(59, 130, 246, 0.05);
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 4px 8px;
  }
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  background: ${tokens.colors.background};
  min-width: 200px;
  border-radius: 8px;
  box-shadow: ${tokens.shadows.lg};
  border: 1px solid ${tokens.colors.border};
  opacity: ${props => props.isOpen ? 1 : 0};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  transform: ${props => props.isOpen ? 'translateY(8px)' : 'translateY(0)'};
  transition: all 0.2s ease;
  z-index: 1000;
`;

const DropdownItem = styled(Link)`
  display: flex;
  padding: 12px 16px;
  color: ${tokens.colors.text.secondary};
  text-decoration: none;
  transition: ${tokens.transitions.standard};
  
  &:first-child {
    border-radius: 8px 8px 0 0;
  }
  
  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  &:hover {
    background: rgba(59, 130, 246, 0.05);
    color: ${tokens.colors.primary};
  }
`;

interface NavbarProps {
  leftNavItems?: Array<{
    label: string;
    to: string;
    onClick?: () => void;
    isButton?: boolean;
  }>;
  navItems?: Array<{
    label: string;
    to: string;
    onClick?: () => void;
    isButton?: boolean;
  }>;
  showSignOutButton?: boolean;
  isSticky?: boolean;
  currentStep?: number;
}

const Navbar: React.FC<NavbarProps> = ({
  leftNavItems = [],
  navItems = [],
  showSignOutButton = false,
  isSticky = false,
  currentStep = 0,
}) => {
  const [user] = useAuthState(auth);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isProductDropdownOpen && !(event.target as Element).closest('.product-dropdown')) {
        setIsProductDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isProductDropdownOpen]);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <NavbarContainer isSticky={isSticky}>
      <LeftContainer>
        <Logo to="/">
          <img src={logoImage} alt="Logo" />
          PhotoOp
        </Logo>
        <LeftNavLinks>
          {leftNavItems.map((item, index) =>
            item.isButton ? (
              <NavButton key={index} to={item.to} onClick={item.onClick}>
                {item.label}
              </NavButton>
            ) : (
              <NavLinkStyled key={index} to={item.to} onClick={item.onClick}>
                {item.label}
              </NavLinkStyled>
            )
          )}
        </LeftNavLinks>
      </LeftContainer>
      {isLargeScreen && currentStep !== 0 && <Stepper currentStep={currentStep} />}
      <NavLinks>
        <ProductDropdown className="product-dropdown">
          <DropdownButton
            onClick={() => setIsProductDropdownOpen(!isProductDropdownOpen)}
          >
            Products
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                transform: isProductDropdownOpen ? 'rotate(180deg)' : 'rotate(0)',
                transition: 'transform 0.2s ease'
              }}
            >
              <path
                d="M2 4L6 8L10 4"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </DropdownButton>
          <DropdownContent isOpen={isProductDropdownOpen}>
            <DropdownItem to="/select-template">AI Portraits</DropdownItem>
            <DropdownItem to="/custom-models">Custom AI Models</DropdownItem>
          </DropdownContent>
        </ProductDropdown>
        {navItems.map((item, index) =>
          item.isButton ? (
            <NavButton key={index} to={item.to} onClick={item.onClick}>
              {item.label}
            </NavButton>
          ) : (
            <NavLinkStyled key={index} to={item.to} onClick={item.onClick}>
              {item.label}
            </NavLinkStyled>
          )
        )}
        {showSignOutButton && user && (
          <SignOutButton onClick={handleSignOut}>Sign Out</SignOutButton>
        )}
      </NavLinks>
    </NavbarContainer>
  );
};

export default Navbar;