// src/pages/SupportPage.tsx
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import OrderSupport from './OrderSupport';
import Navbar from './Navbar';

// Styled Components
const PageContainer = styled.div`
  min-height: 100vh;
  background-color: #f5f7fa;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 48px 24px;
  
  @media (max-width: 768px) {
    padding: 36px 18px;
  }
`;

const HeaderContainer = styled.div`
  margin-bottom: 32px;
  animation: fadeIn 0.8s ease-in;
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const PageTitle = styled.h1`
  font-size: 1.875rem;
  font-weight: 700;
  color: #1E293B;
  margin: 0 0 8px 0;
  letter-spacing: -0.02em;
  line-height: 1.3;
  background: linear-gradient(135deg, #1E293B 0%, #334155 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 48px;
    height: 3px;
    background: linear-gradient(135deg, #3B82F6 0%, #2563EB 100%);
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const OrderNumber = styled.p`
  font-size: 1rem;
  color: #64748B;
  margin: 16px 0 0 0;
  font-weight: 500;
`;

const SupportContainer = styled.div`
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border: 1px solid #E2E8F0;
  padding: 32px;
  transition: box-shadow 0.3s ease;
  
  &:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  }

  @media (max-width: 768px) {
    padding: 24px;
  }
`;


const BackButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  color: #64748B;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #F8FAFC;
    color: #1E293B;
  }

  &:focus {
    outline: none;
    ring: 2px;
    ring-offset: 2px;
    ring-color: #3B82F6;
  }
`;

const SupportPage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Get orderId from URL parameters
    const params = new URLSearchParams(location.search);
    const orderId = params.get('orderId');

    if (!orderId) {
        // If no orderId is provided, redirect back to orders page
        navigate('/orders');
        return null;
    }

    const handleBack = () => {
        navigate('/orders');
    };

    return (
        <PageContainer>
            <Navbar
                navItems={[
                    {
                        label: 'Orders',
                        to: '/orders',
                    },
                ]}
                showSignOutButton={true}
                currentStep={0}
            />

            <ContentContainer>
                <HeaderContainer>
                    <BackButton onClick={handleBack}>
                        ← Back to Orders
                    </BackButton>
                    <PageTitle>Order Support</PageTitle>
                    <OrderNumber>Order #{orderId}</OrderNumber>
                </HeaderContainer>

                <SupportContainer>
                    <OrderSupport orderId={orderId} />
                </SupportContainer>
            </ContentContainer>
        </PageContainer>
    );
};

export default SupportPage;