// src/components/OrderCompletePage.tsx

import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaEnvelope, FaDownload, FaCog } from 'react-icons/fa';
import {
  ref,
  listAll,
  getDownloadURL,
} from 'firebase/storage';
import {
  collection,
  doc,
  setDoc,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
} from 'firebase/firestore';
import { storage, db, auth } from './firebaseConfig';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import styled, { createGlobalStyle, keyframes, css } from 'styled-components';
import Navbar from './Navbar';
import OrderReview from './OrderReview';
import JSZip from 'jszip';

// Import new icons
import { FaRobot, FaBolt, FaBrain } from 'react-icons/fa';

const createFirestoreEntry = async (orderId: string, reviewData: any) => {
  try {
    await setDoc(doc(db, 'reviews', orderId), reviewData);
    console.log(`Firestore entry created successfully for order ID: ${orderId}`);
  } catch (error) {
    console.error('Error creating Firestore entry:', error);
    throw new Error('Failed to create order in Firestore.');
  }
};

const colors = {
  primary: '#2563eb', // Modern blue
  secondary: '#1e40af', // Darker blue
  success: '#059669', // Emerald green
  background: '#f8fafc', // Light gray background
  surface: '#ffffff',
  text: {
    primary: '#1e293b',
    secondary: '#64748b',
    light: '#94a3b8'
  },
  border: '#e2e8f0',
  hover: {
    primary: '#1d4ed8',
    surface: '#f1f5f9'
  }
};

// Updated styled components
const ProgressCard = styled.div`
  background: #ffffff;
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 24px auto;
`;

const Header = styled.div`
  margin-bottom: 32px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  color: #1e293b;
  margin: 0 0 8px 0;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #64748b;
  margin: 0;
`;

const StatusBadge = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  background: rgba(245, 158, 11, 0.1);
  color: #f59e0b;
  margin-bottom: 24px;
`;

const ProgressSection = styled.div`
  margin: 24px 0;
`;

const ProgressBarWrapper = styled.div`
  background: #f1f5f9;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
  margin: 24px 0;
`;

const ProgressBar = styled.div<{ progress: number }>`
  width: ${props => props.progress}%;
  height: 100%;
  background: linear-gradient(90deg, #3b82f6, #7c3aed);
  transition: width 0.3s ease-in-out;
`;

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
  margin-top: 32px;
`;

const DetailCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

const DetailLabel = styled.div`
  font-size: 12px;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 8px;
`;

const DetailValue = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #1e293b;
`;

const SpinningCog = styled(FaCog) <{ isSpinning: boolean }>`
  animation: ${props => props.isSpinning ? 'spin 2s linear infinite' : 'none'};
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  } 
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StageLabel = styled.p<{ isActive: boolean }>`
  margin-top: 12px;
  font-size: 1rem;
  font-weight: ${props => props.isActive ? '600' : '500'};
  color: #1e293b;
  text-align: center;
`;

const StageDescription = styled.p`
  margin-top: 6px;
  font-size: 0.875rem;
  color: #64748b;
  text-align: center;
  max-width: 180px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8fafc;
`;

const InProgressContainer: React.FC<{ order: OrderData }> = ({ order }) => {
  const [progress, setProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState<{ hours: number; minutes: number }>({
    hours: 3,
    minutes: 0
  });

  const computeCurrentStage = (orderTimestamp?: string): number => {
    if (!orderTimestamp) return 0;
    const orderTime = new Date(orderTimestamp);
    const now = new Date();
    const timeDiff = now.getTime() - orderTime.getTime();
    const hoursDiff = timeDiff / (1000 * 60 * 60);

    if (hoursDiff < 1) {
      return 0;
    } else if (hoursDiff < 2) {
      return 1;
    } else {
      return 2;
    }
  };

  useEffect(() => {
    const startTime = new Date(order.timestamp || Date.now());

    const interval = setInterval(() => {
      const now = new Date();
      const elapsedTime = (now.getTime() - startTime.getTime()) / (1000 * 60 * 60); // Hours
      const progress = Math.min((elapsedTime / 3) * 100, 100); // Assuming 3 hours training time
      const remainingTime = Math.max(3 - elapsedTime, 0);

      const hours = Math.floor(remainingTime);
      const minutes = Math.round((remainingTime - hours) * 60);

      setProgress(progress);
      setRemainingTime({ hours, minutes });
    }, 3000);

    return () => clearInterval(interval);
  }, [order.timestamp]);

  return (
    <ProgressCard>
      <Header>
        <Title>Order Progress</Title>
        <Subtitle>Track your order's status and estimated completion time</Subtitle>
      </Header>

      <StatusBadge>
        <SpinningCog size={14} isSpinning={true} />
        Processing in Progress
      </StatusBadge>

      <ProgressSection>
        <ProgressBarWrapper>
          <ProgressBar progress={progress} />
        </ProgressBarWrapper>

        <DetailValue style={{ textAlign: 'center', marginBottom: '8px' }}>
          {progress.toFixed(1)}% Complete
        </DetailValue>

        <Subtitle style={{ textAlign: 'center' }}>
          Estimated time remaining: {remainingTime.hours}h {remainingTime.minutes}m
        </Subtitle>
      </ProgressSection>

      <ProgressStages currentStage={computeCurrentStage(order.timestamp)} />

      {/* <DetailsGrid>
        <DetailCard>
          <DetailLabel>Order ID</DetailLabel>
          <DetailValue>{order.orderId}</DetailValue>
        </DetailCard>

        <DetailCard>
          <DetailLabel>Started At</DetailLabel>
          <DetailValue>
            {order.timestamp ? new Date(order.timestamp).toLocaleString() : 'N/A'}
          </DetailValue>
        </DetailCard>

        <DetailCard>
          <DetailLabel>Style</DetailLabel>
          <DetailValue>{order.templateIds?.[0] || 'Custom'}</DetailValue>
        </DetailCard>

        <DetailCard>
          <DetailLabel>Status</DetailLabel>
          <DetailValue>In Progress</DetailValue>
        </DetailCard>
      </DetailsGrid> */}
    </ProgressCard>
  );
};

const InProgressIcon = styled.div`
  font-size: 3.5rem;
  margin-bottom: 24px;
  animation: ${fadeIn} 0.8s ease-in;
  color: #2563eb;
`;

const InProgressTitle = styled.h2`
  font-size: 1.875rem;
  font-weight: 600;
  margin: 0 0 16px 0;
  animation: ${fadeIn} 1s ease-in;
  color: #1e293b;
`;

const ProgressContainer = styled.div`
  width: 100%;
  max-width: 800px;
  animation: ${fadeIn} 1.2s ease-in;
`;

const InProgressMessage = styled.p`
  font-size: 1rem;
  margin: 16px 0;
  max-width: 600px;
  text-align: center;
  animation: ${fadeIn} 1.4s ease-in;
  color: #64748b;
  line-height: 1.6;

  strong {
    color: #1e293b;
    font-weight: 600;
  }
`;

const Message = styled.p`
  font-size: 1rem;
  color: #64748b;
  margin: 16px 0;
  line-height: 1.6;
`;

const ErrorMessage = styled.p`
  font-size: 1rem;
  color: #dc2626;
  margin: 16px 0;
  line-height: 1.6;
`;

const GoogleSignInButton = styled.button`
  background-color: #4285f4;
  color: white;
  padding: 12px 24px;
  font-size: 1rem;
  margin-top: 20px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(66, 133, 244, 0.2);

  &:hover {
    background-color: #357abf;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(66, 133, 244, 0.25);
  }
`;

const OrderHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`;

const OrderTitle = styled.span`
  font-size: 1.125rem;
  font-weight: 600;
  color: #1e293b;
`;

const AccordionIcon = styled.span<{ isOpen: boolean }>`
  font-size: 1.25rem;
  transition: transform 0.3s ease;
  transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
  color: #64748b;
`;

const AccordionContent = styled.div<{ isOpen: boolean }>`
  display: ${props => props.isOpen ? 'block' : 'none'};
  padding: ${props => props.isOpen ? '24px' : '0'};
  border-top: ${props => props.isOpen ? '1px solid #e2e8f0' : 'none'};
`;

const Footer = styled.footer`
  padding: 24px 60px;
  background: #ffffff;
  border-top: 1px solid #e2e8f0;
  display: flex;
  justify-content: center;
  z-index: 2;

  @media (max-width: 768px) {
    padding: 20px 30px;
  }
`;

const FooterText = styled.p`
  color: #64748b;
  font-size: 0.875rem;
  margin: 0;
`;

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const spinnerBorderAnimation = keyframes`
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
`;

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    background-color: ${colors.background};
    color: ${colors.text.primary};
    box-sizing: border-box;
    height: 100%;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  *, *::before, *::after {
    box-sizing: inherit;
  }
`;

const Container = styled.div`
  max-width: 1600px; // Increased from 1200px
  width: 95%; // Added to ensure some margin on very wide screens
  margin: 0 auto;
  padding: 40px 24px;
  flex: 1;

  @media (max-width: 768px) {
    padding: 32px 20px;
    width: 100%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  gap: 24px;
`;

const SupportButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background-color: ${colors.surface};
  color: ${colors.text.primary};
  border: 1px solid ${colors.border};
  text-decoration: none;
  border-radius: 12px;
  font-size: 0.9375rem;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  margin-left: 12px;

  &:hover {
    background-color: ${colors.hover.surface};
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  svg {
    margin-right: 8px;
    color: ${colors.primary};
  }
`;

const ProgressStagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 40px 0;
  padding: 20px;
  gap: 32px;
`;

const Stage = styled.div<{ isActive: boolean; isCompleted: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 30px;
    left: calc(50% + 30px);
    width: calc(100% - 60px);
    height: 3px;
    background-color: ${props => props.isCompleted ? colors.primary : colors.border};
    z-index: 1;
    transition: background-color 0.3s ease;
  }
`;

const StageIcon = styled.div<{ isActive: boolean; isCompleted: boolean }>`
  width: 60px;
  height: 60px;
  background-color: ${props =>
    props.isCompleted ? colors.primary :
      props.isActive ? colors.primary :
        colors.background};
  border: 2px solid ${props =>
    props.isCompleted || props.isActive ? colors.primary :
      colors.border};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props =>
    props.isCompleted || props.isActive ? colors.surface :
      colors.text.secondary};
  font-size: 1.5rem;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;

  ${props => props.isActive && css`
    &:after {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border: 2px solid ${colors.primary};
      border-radius: 50%;
      opacity: 0.5;
      animation: ${spinnerBorderAnimation} 1.5s linear infinite;
    }
  `}
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 24px;
  margin: 32px 0;

  @media (max-width: 640px) {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 16px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  background: ${colors.surface};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

const DownloadIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(4px);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  color: ${colors.surface};
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  opacity: 0;
  transform: translateY(-4px);

  ${ImageWrapper}:hover & {
    opacity: 1;
    transform: translateY(0);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.9);
    transform: translateY(-1px);
  }
`;

const DownloadButton = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
  padding: 12px 24px;
  background-color: ${colors.primary};
  color: ${colors.surface};
  text-decoration: none;
  border-radius: 12px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(37, 99, 235, 0.2);

  &:hover {
    background-color: ${colors.secondary};
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(37, 99, 235, 0.25);
  }

  svg {
    font-size: 1.1em;
  }
`;

const AccordionItem = styled.div`
  background-color: ${colors.surface};
  border: 1px solid ${colors.border};
  border-radius: 16px;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
  }
`;

const AccordionHeader = styled.div<{ isOpen: boolean }>`
  padding: 20px 24px;
  cursor: pointer;
  background-color: ${props => props.isOpen ? colors.hover.surface : colors.surface};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${colors.hover.surface};
  }
`;

const OrderMeta = styled.span<{ isOpen: boolean }>`
  font-size: 0.9375rem;
  color: ${colors.text.secondary};
  margin-top: 4px;
  line-height: 1.5;
`;

// Update stagesData with imported icons
const stagesData = [
  { label: 'Processing Order', icon: <FaBolt />, description: 'We’re processing your order details.' },
  { label: 'Training Model', icon: <FaBrain />, description: 'Your custom model is being trained.' },
  { label: 'Generating Photos', icon: <FaRobot />, description: 'We’re generating your portraits.' },
];

const ProgressStages = ({ currentStage }: { currentStage: number }) => (
  <ProgressStagesContainer>
    {stagesData.map((stage, index) => (
      <Stage key={index} isActive={index === currentStage} isCompleted={index < currentStage}>
        <StageIcon isActive={index === currentStage} isCompleted={index < currentStage}>
          {stage.icon}
        </StageIcon>
        <StageLabel isActive={index === currentStage}>{stage.label}</StageLabel>
        {index === currentStage && <StageDescription>{stage.description}</StageDescription>}
      </Stage>
    ))}
  </ProgressStagesContainer>
);

// Interface for order data
interface OrderData {
  orderId: string;
  imageUrls: string[];
  inProgress?: boolean;
  timestamp?: string; // Should be a valid date string
  templateIds?: string[];
  ethnicity?: string;
  gender?: string;
  status?: string;
}

const OrderCompletePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [orders, setOrders] = useState<OrderData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState('');
  const [openOrderIds, setOpenOrderIds] = useState<string[]>([]);
  const [user] = useAuthState(auth);
  const [isOrderInProgress, setIsOrderInProgress] = useState(false);

  // State for reviews
  const [rating, setRating] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>('');
  const [isReviewSubmitted, setIsReviewSubmitted] = useState(false);

  const orderId = searchParams.get('orderId') || searchParams.get('order_id');

  const downloadImage = async (url: string, filename: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading image:', error);
      alert('Failed to download image. Please try again.');
    }
  };

  const downloadAllImages = async (imageUrls: string[], orderId: string) => {
    try {
      const zip = new JSZip(); // Create a new JSZip instance
      const folder = zip.folder(`Order_${orderId}`); // Create a folder in the zip for the images

      // Iterate through image URLs and add each to the zip
      for (const [index, url] of imageUrls.entries()) {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch image at ${url}`);
        }
        const blob = await response.blob();
        const fileName = `image_${index + 1}.png`;
        folder?.file(fileName, blob);
      }

      // Generate the zip file as a Blob
      const zipBlob = await zip.generateAsync({ type: 'blob' });

      // Create a temporary anchor element to download the zip file
      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipBlob);
      link.download = `Order_${orderId}.zip`; // Zip file name
      document.body.appendChild(link); // Append to body
      link.click(); // Trigger download
      document.body.removeChild(link); // Clean up the link element
      URL.revokeObjectURL(link.href); // Revoke the object URL
    } catch (error) {
      console.error('Error downloading images:', error);
    }
  };




  const handleReviewSubmit = async (orderId: string) => {
    if (!user) return;

    try {
      await createFirestoreEntry(orderId, {
        orderId,
        userId: user.uid,
        userEmail: user.email,
        rating,
        feedback,
        timestamp: new Date().toISOString(),
      });

      setIsReviewSubmitted(true);
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };

  const toggleOrder = (orderId: string) => {
    if (openOrderIds.includes(orderId)) {
      setOpenOrderIds(openOrderIds.filter((id) => id !== orderId));
    } else {
      setOpenOrderIds([...openOrderIds, orderId]);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  const computeCurrentStage = (orderTimestamp?: string, imageUrls?: string[]): number => {
    if (imageUrls && imageUrls.length > 0) {
      return 2; // Images are ready
    }
    if (!orderTimestamp) return 0;
    const orderTime = new Date(orderTimestamp);
    const now = new Date();
    const timeDiff = now.getTime() - orderTime.getTime();
    const hoursDiff = timeDiff / (1000 * 60 * 60);

    if (hoursDiff < 1) {
      return 0;
    } else if (hoursDiff < 2) {
      return 1;
    } else {
      return 2;
    }
  };

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const fetchOrders = () => {
      setIsLoading(true);
      setFetchError('');
      setOrders([]);
      setIsOrderInProgress(false);

      if (orderId) {
        // Fetch images and metadata for the specific orderId
        const orderDocRef = doc(db, 'orders', orderId);

        unsubscribe = onSnapshot(
          orderDocRef,
          async (docSnapshot) => {
            if (docSnapshot.exists()) {
              const orderData = docSnapshot.data() as OrderData;
              const orderId = orderData.orderId;

              // Use imageUrls from orderData or fetch from Storage
              let imageUrls: string[] = orderData.imageUrls || [];
              if (imageUrls.length === 0) {
                // Fetch from Storage if necessary
                const storageRef = ref(storage, `user_outputs/${orderId}`);
                const fileList = await listAll(storageRef);

                if (fileList.items.length > 0) {
                  const urlPromises = fileList.items.map((fileRef) =>
                    getDownloadURL(fileRef)
                  );
                  imageUrls = await Promise.all(urlPromises);
                } else {
                  setIsOrderInProgress(true);
                }
              }

              setOrders([{ ...orderData, orderId, imageUrls }]);
              setOpenOrderIds([orderId]);
              setIsOrderInProgress(false);
              setIsLoading(false);
            } else {
              setFetchError(`Order ID ${orderId} not found.`);
              setIsLoading(false);
            }
          },
          (error) => {
            console.error(`Error fetching order ${orderId}:`, error);
            setFetchError(`Error fetching order ID: ${orderId}. ${user?.uid}`);
            setIsLoading(false);
          }
        );
      } else if (user) {
        // Fetch orders for authenticated user
        const ordersRef = collection(db, 'orders');
        const q = query(
          ordersRef,
          where('userId', '==', user.uid),
          orderBy('timestamp', 'desc'),
          limit(5)
        );

        unsubscribe = onSnapshot(
          q,
          async (querySnapshot) => {
            if (querySnapshot.empty) {
              setFetchError('No orders found for your account.');
              setIsLoading(false);
              return;
            }

            const fetchedOrders = await Promise.all(
              querySnapshot.docs.map(async (doc) => {
                const orderData = doc.data() as OrderData;
                const orderId = orderData.orderId;
                if (!orderId) return null;

                let imageUrls: string[] = orderData.imageUrls || [];
                if (imageUrls.length === 0) {
                  // Fetch from Storage if necessary
                  const storageRef = ref(storage, `user_outputs/${orderId}`);
                  const fileList = await listAll(storageRef);

                  if (fileList.items.length > 0) {
                    const urlPromises = fileList.items.map((fileRef) =>
                      getDownloadURL(fileRef)
                    );
                    imageUrls = await Promise.all(urlPromises);
                  }
                }

                return {
                  ...orderData,
                  orderId,
                  imageUrls,
                };
              })
            );

            setOrders(
              fetchedOrders.filter((order) => order !== null) as OrderData[]
            );
            setIsLoading(false);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            setFetchError('An error occurred while fetching your orders.');
            setIsLoading(false);
          }
        );
      } else {
        setIsLoading(false);
      }
    };

    fetchOrders();

    // Clean up the listener on unmount
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [user, orderId]);


  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        <Navbar
          navItems={[
            {
              label: 'Home',
              to: '/',
            },
          ]}
          showSignOutButton={!!user}
        />

        <Container>
          {isOrderInProgress ? (
            <InProgressContainer order={orders[0]} />
          ) : (
            <>
              <HeaderContainer>
                <Title>Your Orders</Title>
              </HeaderContainer>
              {isLoading && <Message>Loading your orders...</Message>}
              {fetchError && <ErrorMessage>{fetchError}</ErrorMessage>}

              {!orderId && !user && (
                <>
                  <Message>Please sign in to view your orders.</Message>
                  <GoogleSignInButton onClick={handleGoogleSignIn}>
                    Sign in with Google
                  </GoogleSignInButton>
                </>
              )}

              {orders.length > 0 ? (
                <>
                  {orders.map((order) => {
                    const currentStage = computeCurrentStage(order.timestamp, order.imageUrls);
                    return (
                      <AccordionItem key={order.orderId}>
                        <AccordionHeader
                          onClick={() => toggleOrder(order.orderId)}
                          isOpen={openOrderIds.includes(order.orderId)}
                          aria-expanded={openOrderIds.includes(order.orderId)}
                          role="button"
                        >
                          <OrderHeaderContent>
                            <OrderTitle>Order Summary</OrderTitle>
                            <OrderMeta isOpen={openOrderIds.includes(order.orderId)}>
                              Placed on:{' '}
                              {order.timestamp
                                ? new Date(order.timestamp).toLocaleDateString('en-US', {
                                  month: 'long',
                                  day: 'numeric',
                                  year: 'numeric',
                                })
                                : 'N/A'}
                            </OrderMeta>
                          </OrderHeaderContent>
                          <AccordionIcon isOpen={openOrderIds.includes(order.orderId)}>
                            {openOrderIds.includes(order.orderId) ? <FaChevronUp /> : <FaChevronDown />}
                          </AccordionIcon>
                        </AccordionHeader>
                        <AccordionContent isOpen={openOrderIds.includes(order.orderId)}>
                          <OrderReview
                            rating={rating}
                            setRating={setRating}
                            feedback={feedback}
                            setFeedback={setFeedback}
                            onSubmit={() => handleReviewSubmit(order.orderId)}
                            isSubmitted={isReviewSubmitted}
                          />
                          {order.imageUrls.length > 0 ? (
                            <>
                              <ImageGrid>
                                {order.imageUrls.map((url, index) => (
                                  <ImageWrapper key={index}>
                                    <Image
                                      src={url}
                                      alt={`Order ${order.orderId} - Image ${index + 1}`}
                                      loading="lazy"
                                    />
                                    <DownloadIcon
                                      onClick={() => downloadImage(url, `image_${index + 1}.png`)}
                                    >
                                      <FaDownload />
                                    </DownloadIcon>
                                  </ImageWrapper>
                                ))}
                              </ImageGrid>


                              <DownloadButton
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  downloadAllImages(order.imageUrls, order.orderId);
                                }}
                              >
                                Download All Images
                              </DownloadButton>

                              <SupportButton as={Link} to={`/support?orderId=${order.orderId}`}>
                                <FaEnvelope />
                                Need Help With This Order?
                              </SupportButton>
                            </>
                          ) : (
                            <InProgressContainer order={order} />
                          )}

                        </AccordionContent>
                      </AccordionItem>
                    );
                  })}
                </>
              ) : (
                !isLoading &&
                !fetchError && <Message>You have no orders yet.</Message>
              )}
            </>
          )}
        </Container>

        {/* Footer */}
        <Footer>
          <FooterText>
            &copy; {new Date().getFullYear()} PhotoOp. All rights reserved.
          </FooterText>
        </Footer>
      </PageWrapper>
    </>
  );
};

export default OrderCompletePage;
