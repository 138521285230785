import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import ethanLee from './assets/testimonials/ethan.png';
import gopalHari from './assets/testimonials/gopal.png';
import elenaRodriguezImage from './assets/testimonials/elena.png';


// Animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const pulse = keyframes`
  0% { transform: scale(0.95); }
  50% { transform: scale(1.05); }
  100% { transform: scale(0.95); }
`;

// Styled Components
const SectionContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 1rem;
  animation: ${fadeIn} 1s ease-out;
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 1rem;

  span {
    color: #3b82f6;
  }
`;

const Subtitle = styled.p`
  font-size: 1.125rem;
  color: #666;
`;

const StatsBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3rem;
  padding: 1.5rem;
  background: #f0f7ff;
  border-radius: 0.75rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

const StatItem = styled.div`
  text-align: center;
`;

const StatNumber = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #3b82f6;
`;

const StatLabel = styled.div`
  font-size: 0.875rem;
  color: #666;
`;

const TestimonialsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const TestimonialCard = styled.div`
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
`;

const RatingContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const StarIcon = styled.span`
  color: #fbbf24;
  margin-right: 0.25rem;
`;

const TestimonialText = styled.p`
  color: #4b5563;
  font-style: italic;
  margin-bottom: 1.5rem;
  line-height: 1.6;
`;

const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AuthorImage = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 1rem;
`;

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const AuthorName = styled.div`
  font-weight: 600;
  color: #1a1a1a;
`;

const AuthorRole = styled.div`
  font-size: 0.875rem;
  color: #666;
`;

const VerificationInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  font-size: 0.75rem;
`;

const VerificationBadge = styled.span`
  color: #10b981;
  margin-right: 0.5rem;
`;

const VerificationText = styled.span`
  color: #666;
`;

const ActivityBanner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #3b82f6;
  color: white;
  padding: 1rem;
  border-radius: 0.75rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
`;

const PulsingDot = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  background-color: #60a5fa;
  border-radius: 50%;
  margin-right: 0.5rem;
  animation: ${pulse} 2s infinite;
`;

const TestimonialsSection: React.FC = () => {
    const testimonials = [
        {
            name: "Ethan Lee",
            role: "Senior Consultant at Booz Allen",
            image: ethanLee,
            text: "Needed new LinkedIn photos but dreaded the whole professional photoshoot thing. This was so much easier - uploaded a few selfies and got great headshots back. Actually had a recruiter mention how put-together my profile looked!",
            rating: 5,
            platform: "Verified Purchase",
            date: "2 days ago"
        },
        {
            name: "Gopal Hari",
            role: "Software Engineer at Amazon",
            image: gopalHari,
            text: "Was skeptical at first but wow - the results are impressive. Used these for my GitHub and company profile. Way better than the awkward office photos we usually have to use. Definitely recommend to other tech folks.",
            rating: 5,
            platform: "Verified Purchase",
            date: "1 week ago"
        },
        {
            name: "Elena Rodriguez",
            role: "Startup Founder",
            image: elenaRodriguezImage,
            text: "Running a remote team means everyone's profile pics were all over the place - different lighting, backgrounds, quality, you name it. Got the whole team to try this and now our team page actually looks cohesive. Such a simple fix for what was driving me crazy!",
            rating: 5,
            platform: "Verified Purchase",
            date: "3 days ago"
        }
    ];

    return (
        <SectionContainer>
            <SectionHeader>
                <Title>Trusted by <span>5,000+</span> Professionals</Title>
                <Subtitle>Join thousands of satisfied customers who have transformed their professional image</Subtitle>
            </SectionHeader>

            <StatsBar>
                <StatItem>
                    <StatNumber>20,000+</StatNumber>
                    <StatLabel>Portraits Generated</StatLabel>
                </StatItem>
                <StatItem>
                    <StatNumber>4.9/5</StatNumber>
                    <StatLabel>Average Rating</StatLabel>
                </StatItem>
                <StatItem>
                    <StatNumber>98%</StatNumber>
                    <StatLabel>Satisfaction Rate</StatLabel>
                </StatItem>
            </StatsBar>

            <TestimonialsGrid>
                {testimonials.map((testimonial, index) => (
                    <TestimonialCard key={index}>
                        <RatingContainer>
                            {[...Array(testimonial.rating)].map((_, i) => (
                                <StarIcon key={i}>★</StarIcon>
                            ))}
                        </RatingContainer>
                        <TestimonialText>"{testimonial.text}"</TestimonialText>
                        <AuthorContainer>
                            <AuthorImage src={testimonial.image} alt={testimonial.name} />
                            <AuthorInfo>
                                <AuthorName>{testimonial.name}</AuthorName>
                                <AuthorRole>{testimonial.role}</AuthorRole>
                                <VerificationInfo>
                                    <VerificationBadge>✓</VerificationBadge>
                                    <VerificationText>
                                        {testimonial.platform} • {testimonial.date}
                                    </VerificationText>
                                </VerificationInfo>
                            </AuthorInfo>
                        </AuthorContainer>
                    </TestimonialCard>
                ))}
            </TestimonialsGrid>

            <ActivityBanner>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PulsingDot />
                    <span><strong>148 people</strong> generated portraits in the last 24 hours</span>
                </div>
                <div>
                    <strong>Limited time offer:</strong> 30% off for new customers
                </div>
            </ActivityBanner>
        </SectionContainer>
    );
};

export default TestimonialsSection;