import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaCircle, FaCog, FaClock, FaCheckCircle } from 'react-icons/fa';

// Theme configuration matching the sidebar
const theme = {
  colors: {
    background: '#ffffff',
    surface: '#f8fafc',
    primary: '#3b82f6',
    primaryLight: '#60a5fa',
    primaryDark: '#2563eb',
    secondary: '#64748b',
    accent: '#7c3aed',
    success: '#10b981',
    warning: '#f59e0b',
    danger: '#ef4444',
    text: {
      primary: '#1e293b',
      secondary: '#64748b',
      light: '#94a3b8'
    },
    border: '#e2e8f0',
    hover: '#f1f5f9'
  },
  shadows: {
    sm: '0 1px 2px rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1)'
  }
};

// Types
export type Model = {
  id: string;
  trainingStatus: string;
  modelName: string;
  storagePath: string;
  timestamp: any;
  userEmail: string;
  triggerWord?: string;
};

interface ModelProgressUIProps {
  selectedModel: Model;
}

// Styled Components
const ProgressContainer = styled.div`
    flex: 1;
    padding: 32px;
    background: ${theme.colors.background};
    height: 100%;
    overflow-y: auto;

    @media (max-width: 768px) {
        padding: 24px;
    }
`;

const Card = styled.div`
    background: ${theme.colors.surface};
    border-radius: 16px;
    padding: 32px;
    box-shadow: ${theme.shadows.md};
    max-width: 800px;
    margin: 0 auto;
`;

const Header = styled.div`
    margin-bottom: 32px;
`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: 700;
    color: ${theme.colors.text.primary};
    margin: 0 0 8px 0;
`;

const Subtitle = styled.p`
    font-size: 14px;
    color: ${theme.colors.text.secondary};
    margin: 0;
`;

const ProgressSection = styled.div`
    margin: 24px 0;
`;

const ProgressBarWrapper = styled.div`
    background: ${theme.colors.hover};
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
    margin: 24px 0;
`;

const ProgressBar = styled.div<{ progress: number }>`
    width: ${props => props.progress}%;
    height: 100%;
    background: linear-gradient(90deg, ${theme.colors.primary}, ${theme.colors.accent});
    transition: width 0.3s ease-in-out;
`;

const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
`;

const StatusBadge = styled.div<{ status: string }>`
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    
    ${props => {
    switch (props.status) {
      case 'complete':
        return `
                    background: rgba(16, 185, 129, 0.1);
                    color: ${theme.colors.success};
                `;
      case 'inProgress':
        return `
                    background: rgba(245, 158, 11, 0.1);
                    color: ${theme.colors.warning};
                `;
      default:
        return `
                    background: rgba(100, 116, 139, 0.1);
                    color: ${theme.colors.secondary};
                `;
    }
  }}
`;

const DetailsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 24px;
    margin-top: 32px;
`;

const DetailCard = styled.div`
    background: ${theme.colors.background};
    border-radius: 12px;
    padding: 20px;
    box-shadow: ${theme.shadows.sm};
`;

const DetailLabel = styled.div`
    font-size: 12px;
    color: ${theme.colors.text.secondary};
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 8px;
`;

const DetailValue = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: ${theme.colors.text.primary};
`;

const SpinningCog = styled(FaCog) <{ isSpinning: boolean }>`
    animation: ${props => props.isSpinning ? 'spin 2s linear infinite' : 'none'};
    
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

// Helper Functions
const calculateProgress = (startTime: Date) => {
  const now = new Date();
  const elapsedTime = (now.getTime() - startTime.getTime()) / (1000 * 60 * 60); // Hours
  const progress = Math.min((elapsedTime / 3) * 100, 100); // Assuming 3 hours training time
  const remainingTime = Math.max(3 - elapsedTime, 0);

  const hours = Math.floor(remainingTime);
  const minutes = Math.round((remainingTime - hours) * 60);

  return { progress, remainingTime: { hours, minutes } };
};

const formatDate = (timestamp: any) => {
  const date = timestamp instanceof Date ? timestamp : timestamp.toDate();
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date);
};

// Main Component
const ModelProgressUI: React.FC<ModelProgressUIProps> = ({ selectedModel }) => {
  const [progress, setProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState<{ hours: number; minutes: number }>({
    hours: 3,
    minutes: 0
  });

  const startTime = selectedModel.timestamp instanceof Date
    ? selectedModel.timestamp
    : selectedModel.timestamp.toDate();

  useEffect(() => {
    const interval = setInterval(() => {
      const { progress: newProgress, remainingTime: newRemainingTime } = calculateProgress(startTime);
      setProgress(newProgress);
      setRemainingTime(newRemainingTime);
    }, 3000);

    return () => clearInterval(interval);
  }, [startTime]);

  return (
    <ProgressContainer>
      <Card>
        <Header>
          <Title>Model Training Progress</Title>
          <Subtitle>Track your model's training status and estimated completion time</Subtitle>
        </Header>

        <StatusContainer>
          <StatusBadge status={selectedModel.trainingStatus}>
            {selectedModel.trainingStatus === 'complete' ? (
              <FaCheckCircle size={14} />
            ) : (
              <SpinningCog size={14} isSpinning={selectedModel.trainingStatus === 'inProgress'} />
            )}
            {selectedModel.trainingStatus === 'complete' ? 'Training Complete' : 'Training in Progress'}
          </StatusBadge>
        </StatusContainer>

        <ProgressSection>
          <ProgressBarWrapper>
            <ProgressBar progress={progress} />
          </ProgressBarWrapper>

          <DetailValue style={{ textAlign: 'center', marginBottom: '8px' }}>
            {progress.toFixed(1)}% Complete
          </DetailValue>

          {selectedModel.trainingStatus === 'inProgress' && (
            <Subtitle style={{ textAlign: 'center' }}>
              Estimated time remaining: {remainingTime.hours}h {remainingTime.minutes}m
            </Subtitle>
          )}
        </ProgressSection>

        <DetailsGrid>
          <DetailCard>
            <DetailLabel>Model Name</DetailLabel>
            <DetailValue>{selectedModel.modelName}</DetailValue>
          </DetailCard>

          <DetailCard>
            <DetailLabel>Trigger Word</DetailLabel>
            <DetailValue>{selectedModel.triggerWord || 'Default'}</DetailValue>
          </DetailCard>

          <DetailCard>
            <DetailLabel>Started At</DetailLabel>
            <DetailValue>{formatDate(selectedModel.timestamp)}</DetailValue>
          </DetailCard>

          <DetailCard>
            <DetailLabel>Created By</DetailLabel>
            <DetailValue>{selectedModel.userEmail.split('@')[0]}</DetailValue>
          </DetailCard>
        </DetailsGrid>
      </Card>
    </ProgressContainer>
  );
};

export default ModelProgressUI;