import React, { useEffect, useState } from 'react';
import { templateCategories } from './templates';

const ImagePreloader = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const preloadImages = async () => {
            try {
                // Get all template categories and their images
                const allTemplates = templateCategories.flatMap(category =>
                    category.templates.map(template => ({
                        afterImage: template.afterImage,
                        beforeImage: template.beforeImage
                    }))
                );

                // Create an array of promises for image loading
                const imagePromises = allTemplates.flatMap(template => {
                    const promises = [];

                    // Create promise for each image
                    const loadImage = (src: string) => {
                        return new Promise<void>((resolve, reject) => {
                            const img = new Image();

                            img.onload = () => resolve();
                            img.onerror = () => reject();

                            img.src = src;
                        });
                    };

                    if (template.afterImage) {
                        promises.push(loadImage(template.afterImage));
                    }
                    if (template.beforeImage) {
                        promises.push(loadImage(template.beforeImage));
                    }

                    return promises;
                });

                // Wait for all images to load
                await Promise.all(imagePromises);
                setIsLoading(false);

            } catch (error) {
                console.error('Error preloading images:', error);
                setIsLoading(false);
            }
        };

        preloadImages();
    }, []);

    // Return null since this is a utility component
    return null;
};

export default ImagePreloader;