import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ShoppingCart, DollarSign, Star, Tags, ChevronRight, Shield } from 'lucide-react';

const theme = {
    colors: {
        background: '#ffffff',
        surface: '#f8fafc',
        primary: {
            lighter: '#eff6ff', // blue-50
            light: '#60a5fa',   // blue-400
            main: '#2563eb',    // blue-600
            dark: '#1e40af',    // blue-800
            text: '#1e3a8a'     // blue-900
        },
        slate: {
            50: '#f8fafc',
            100: '#f1f5f9',
            200: '#e2e8f0',
            300: '#cbd5e1',
            400: '#94a3b8',
            500: '#64748b',
            600: '#475569',
            900: '#0f172a'
        }
    }
};

const SidebarContainer = styled.aside`
    width: 320px;
    height: 100vh;
    background: ${theme.colors.background};
    border-right: 1px solid ${theme.colors.slate[200]};
    display: flex;
    flex-direction: column;
    margin-right: 24px;

    @media (max-width: 768px) {
        display: none;
    }
`;

const ScrollableContent = styled.div`
    min-height: 0 ;
    flex: 1 1 0;
    overflow-y: auto;
    padding: 24px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: ${theme.colors.slate[300]};
        border-radius: 3px;
    }
`;

const SummaryCard = styled.div`
    background: linear-gradient(135deg, ${theme.colors.primary.main}, ${theme.colors.primary.dark});
    border-radius: 16px;
    padding: 28px;
    color: white;
    margin-bottom: 24px;
    box-shadow: 0 10px 20px rgba(79, 70, 229, 0.15);
    transition: transform 0.2s ease;
`;

const CardHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    h2 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        letter-spacing: 0.5px;
    }
`;

const IconWrapper = styled.div`
    background: rgba(255, 255, 255, 0.15);
    padding: 8px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StatsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
`;

const StatItem = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const StatValue = styled.p`
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 4px;
`;

const StatLabel = styled.p`
    font-size: 14px;
    opacity: 0.85;
    margin: 0;
    font-weight: 500;
`;

const SectionTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    color: ${theme.colors.slate[600]};
    font-size: 14px;
    font-weight: 500;
`;

const FilterContainer = styled.div`
    margin-bottom: 24px;
`;

const FilterButtons = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const FilterButton = styled.button<{ active?: boolean }>`
    padding: 6px 12px;
    background: ${props => props.active ? theme.colors.primary.lighter : theme.colors.slate[50]};
    color: ${props => props.active ? theme.colors.primary.text : theme.colors.slate[600]};
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        background: ${props => props.active ? theme.colors.primary.lighter : theme.colors.slate[100]};
    }
`;

const CategoryList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const CategoryItem = styled.label`
    display: flex;
    align-items: center;
    padding: 12px;
    background: white;
    border: 1px solid ${theme.colors.slate[200]};
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.15s ease;

    &:hover {
        background: ${theme.colors.slate[50]};
    }
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    width: 16px;
    height: 16px;
    margin: 0;
    margin-right: 12px;
`;

const CategoryContent = styled.div`
    flex: 1;
    margin-right: 8px;
`;

const CategoryName = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.colors.slate[900]};
`;

const PopularTag = styled.span`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    margin-left: 8px;
    padding: 2px 8px;
    background: ${theme.colors.primary.lighter};
    color: ${theme.colors.primary.main};
    border-radius: 100px;
    font-size: 12px;
    font-weight: 500;
`;

const TemplateCount = styled.div`
    font-size: 12px;
    color: ${theme.colors.slate[500]};
    margin-top: 2px;
`;

const ProTipSection = styled.div`
    padding: 24px;
    border-top: 1px solid ${theme.colors.slate[200]};
    flex-shrink: 0
    background: ${theme.colors.background}
`;

const ProTipCard = styled.div`
    background: ${theme.colors.slate[50]};
    border-radius: 12px;
    padding: 16px;
`;

const ProTipTitle = styled.h4`
    font-size: 14px;
    font-weight: 500;
    color: ${theme.colors.slate[900]};
    margin: 0 0 4px 0;
`;

const ProTipText = styled.p`
    font-size: 12px;
    color: ${theme.colors.slate[600]};
    margin: 0;
    line-height: 1.5;
`;

const shimmerAnimation = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const AIPhotosCard = styled.div`
  position: relative;
  background: linear-gradient(135deg, #eff6ff 0%, #dbeafe 100%);
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  border: 1px solid rgba(37, 99, 235, 0.1);
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06),
    inset 0 1px 0 rgba(255, 255, 255, 0.6);
  transition: all 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      45deg,
      transparent 25%,
      rgba(255, 255, 255, 0.1) 25%,
      rgba(255, 255, 255, 0.1) 50%,
      transparent 50%,
      transparent 75%,
      rgba(255, 255, 255, 0.1) 75%
    );
    background-size: 24px 24px;
    opacity: 0.6;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    animation: ${shimmerAnimation} 8s infinite linear;
  }
`;

const AIPhotosIcon = styled.div`
  background: white;
  border-radius: 12px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.primary.main};
  position: relative;
  z-index: 1;
  box-shadow: 
    0 4px 6px rgba(37, 99, 235, 0.1),
    0 2px 4px rgba(37, 99, 235, 0.06);
  
  svg {
    filter: drop-shadow(0 2px 4px rgba(37, 99, 235, 0.2));
    transition: transform 0.3s ease;
  }

  ${AIPhotosCard}:hover & svg {
    transform: scale(1.1);
  }
`;

const AIPhotosContent = styled.div`
  flex: 1;
  position: relative;
  z-index: 1;
`;

const AIPhotosTitle = styled.h3`
  margin: 0 0 8px 0;
  font-size: 14px;
  font-weight: 700;
  color: ${theme.colors.primary.text};
  letter-spacing: -0.01em;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
`;

const AIPhotosDescription = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  color: ${theme.colors.primary.text};
  opacity: 0.85;
  font-weight: 500;
`;

const PhotoCountBadge = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  color: white;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: 100px;
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.2);
  border: 2px solid white;
`;

const GuaranteeContainer = styled.div`
  background: linear-gradient(135deg, ${theme.colors.primary.lighter}, #f0f9ff);
  border: 1px solid ${theme.colors.primary.light}20;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(37, 99, 235, 0.05);
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(37, 99, 235, 0.1);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      45deg,
      transparent 25%,
      rgba(255, 255, 255, 0.1) 25%,
      rgba(255, 255, 255, 0.1) 50%,
      transparent 50%,
      transparent 75%,
      rgba(255, 255, 255, 0.1) 75%
    );
    background-size: 8px 8px;
    opacity: 0.5;
  }
`;

const GuaranteeIconBox = styled.div`
  width: 42px;
  height: 42px;
  background: linear-gradient(135deg, ${theme.colors.primary.main}, ${theme.colors.primary.dark});
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.2);
  position: relative;
  z-index: 1;
  
  &::after {
    content: '';
    position: absolute;
    inset: -1px;
    background: linear-gradient(135deg, ${theme.colors.primary.light}40, transparent);
    border-radius: inherit;
    z-index: -1;
  }
`;

const GuaranteeContent = styled.div`
  flex: 1;
`;

const GuaranteeTitle = styled.h3`
  color: ${theme.colors.primary.text};
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 4px 0;
  display: flex;
  align-items: center;
  gap: 6px;
  
  &::after {
    content: '✓';
    background: ${theme.colors.primary.main}15;
    color: ${theme.colors.primary.main};
    font-size: 10px;
    padding: 2px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

const GuaranteeText = styled.p`
  color: ${theme.colors.slate[500]};
  font-size: 11px;
  line-height: 1.5;
  margin: 0;
  position: relative;
  z-index: 1;
  letter-spacing: -0.1px;
`;

// MoneyBackGuarantee component
const MoneyBackGuarantee: React.FC = () => (
    <GuaranteeContainer>
        <GuaranteeIconBox>
            <Shield size={20} color="white" strokeWidth={2.5} />
        </GuaranteeIconBox>
        <GuaranteeContent>
            <GuaranteeTitle>30-Day Money Back Guarantee</GuaranteeTitle>
            <GuaranteeText>
                100% satisfaction guaranteed. Full refund available if you're not completely satisfied.
            </GuaranteeText>
        </GuaranteeContent>
    </GuaranteeContainer>
);

interface TemplateSidebarProps {
    categories: Array<{
        name: string;
        templates: Array<any>;
    }>;
    selectedCategories: string[];
    onCategoryToggle: (category: string) => void;
    searchTerm: string;
    onSearchChange: (term: string) => void;
    selectedTemplatesCount: number;
    totalPrice: number;
}

const TemplateSidebar: React.FC<TemplateSidebarProps> = ({
    categories = [],
    selectedCategories = [],
    onCategoryToggle = () => { },
    searchTerm = "",
    onSearchChange = () => { },
    selectedTemplatesCount = 0,
    totalPrice = 0
}) => {
    return (
        <SidebarContainer>
            <ScrollableContent>
                <SummaryCard>
                    <CardHeader>
                        <IconWrapper>
                            <ShoppingCart size={20} />
                        </IconWrapper>
                        <h2>Your Cart</h2>
                    </CardHeader>
                    <StatsGrid>
                        <StatItem>
                            <StatValue>
                                {selectedTemplatesCount}
                            </StatValue>
                            <StatLabel>Templates</StatLabel>
                        </StatItem>
                        <StatItem>
                            <StatValue>
                                <DollarSign size={20} />
                                {totalPrice.toFixed(2)}
                            </StatValue>
                            <StatLabel>Total Price</StatLabel>
                        </StatItem>
                    </StatsGrid>
                </SummaryCard>

                {/* <SearchContainer>
                    <IconWrapper>
                        <Search size={16} />
                    </IconWrapper>
                    <SearchInput
                        placeholder="Search templates..."
                        value={searchTerm}
                        onChange={(e) => onSearchChange(e.target.value)}
                    />
                </SearchContainer> */}

                <MoneyBackGuarantee />
                {/* Filter section removed
                <FilterContainer>
                    <SectionTitle>
                        <Filter size={16} />
                        Filters
                    </SectionTitle>
                    <FilterButtons>
                        <FilterButton active>Professional</FilterButton>
                        <FilterButton>Casual</FilterButton>
                        <FilterButton>Creative</FilterButton>
                    </FilterButtons>
                </FilterContainer>
                */}

                <div>
                    <SectionTitle>
                        <Tags size={16} />
                        Categories
                    </SectionTitle>
                    <CategoryList>
                        {categories.map((category) => (
                            <CategoryItem key={category.name}>
                                <Checkbox
                                    checked={selectedCategories.includes(category.name)}
                                    onChange={() => onCategoryToggle(category.name)}
                                />
                                <CategoryContent>
                                    <CategoryName>
                                        {category.name}
                                        {category.name === "LinkedIn Professional" && (
                                            <PopularTag>
                                                <Star size={10} />
                                                Popular
                                            </PopularTag>
                                        )}
                                    </CategoryName>
                                    <TemplateCount>
                                        {category.templates.length} templates
                                    </TemplateCount>
                                </CategoryContent>
                                <ChevronRight size={16} color={theme.colors.slate[400]} />
                            </CategoryItem>
                        ))}
                    </CategoryList>
                </div>
            </ScrollableContent>

            <ProTipSection>
                <ProTipCard>
                    <ProTipTitle>Pro tip</ProTipTitle>
                    <ProTipText>
                        Mix templates from different categories to get diverse headshots for various platforms.
                    </ProTipText>
                </ProTipCard>
            </ProTipSection>
        </SidebarContainer>
    );
};

export default TemplateSidebar;